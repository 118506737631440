import React, { useEffect, useState } from 'react'
import styles from './SendBox.module.scss'
import { Button } from "@material-ui/core";
import axios from 'axios'

export const SendBox = (props) => {
  const [text, setText] = useState('')

  const sendMessage = () => {
    if (text.length === 0) return
    setText('')
    axios.post('https://twilio-server-snap.herokuapp.com/webhook/send', { Text: text, To: props.to, From: props.twilioNumber })
    // axios.post('http://localhost:5050/webhook/send', { Text: text, To: props.to, From: props.twilioNumber })
      .then(jsnRes => {
      })
      .catch(err => {
        console.log(err)
      })
  }
 
    
    const sendMessageOnEnter = (text, to, from) => {
      if(text.length === 0) return
      setText('')
      axios.post('https://twilio-server-snap.herokuapp.com/webhook/send', { Text: text, To: props.to, From: props.twilioNumber })
      // axios.post('http://localhost:5050/webhook/send', { Text: text, To: props.to, From: props.twilioNumber })
        .catch(err => {
          console.log(err)
        })

    }
  

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
       
        event.preventDefault();
        sendMessageOnEnter(text, props.to, props.twilioNumber)
      }
    }
    document.addEventListener("keyup", listener)

    return () => {
      document.removeEventListener("keyup", listener);
    }
  }, [text, props.To, props.twilioNumber])

  return (
    <div className={styles.compose}>
      <input
        id='click-enter'
        type="text"
        value={text}
        onChange={(e) => { setText(e.target.value) }}
        className={styles.composeInput}
        placeholder="Type a message"
      />
      <Button disabled={text === ''} onClick={sendMessage}>Send Message</Button>
    </div>
  )
}