import React, { useEffect, useState } from "react";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './Demo.module.scss'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import {dealerOneUser, dealerTwoUser, dealerThreeUser} from './DemoData'
import {BasicTable} from './Table'
import {Link} from "react-router-dom";
import {BasicTableSearch} from './TableSearch'
const useStyles = makeStyles((theme)=>({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        minWidth: '100%',
        height: '100%'
    },
    leadCard: {
        minWidth: '100%',
        height: '50%'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    formControl: {
        margin: 5,
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: 10,
      },
}));

export const Demo = props => {
    const classes = useStyles();
    const [vinDealer, setDealer] = useState('');
    const [dealerUsers, setDealerUsers] = useState('')
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    // const [vin, setVin] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [notes, setNotes] = useState('')
    const [foundUsers, setFoundUsers] = useState('')
    const [error, setError] = useState(false)

    const handleFirst = (e) => {
        setFirst(e.target.value)
    }
    const handleLast = (e) => {
        setLast(e.target.value)
    }
    // const handleVin = (e) => {
    //     setVin(e.target.value)
    // }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handlePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleNotes = (e) => {
        setNotes(e.target.value)
    }
    const handleChange = (event) => {
        setDealer(event.target.value);
        
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpenError = () => {
        setOpenError(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    const handleErrorClose = () => {
        setOpenError(false);
    };
    const submitLead = async () => {
        setLoading(true)
        const {data} = await axios.post('http://localhost:5000/vin-solutions/create-contact', {
            "note": notes,
            "first": first,
            "last": last,
            "email": email,
            "phone": phone,
            "salesNote": notes
        })
        if(data.check === "Lead is already active"){
            handleOpenError()
            setError('Lead is already active')
            setOpen(false)
            setLoading(false)
        }else if(data.check){
            setOpen(false)
            setLoading(false)
        }
        console.log(data)
    };

    useEffect(()=>{
        if(error){
            setTimeout(handleErrorClose, 2000)
        }
    }, [error])

    useEffect( ()=>{
        axios.get('http://localhost:5000/vin-solutions/get-clients')
        .then(data => {
            console.log(data.data)
            setFoundUsers(data.data)
        })
    }, [])

    useEffect(()=>{
        if(vinDealer === 1){
            setDealerUsers(dealerOneUser)
        } else if(vinDealer === 2){
            setDealerUsers(dealerTwoUser)
        } else if(vinDealer === 3){
            setDealerUsers(dealerThreeUser)
        }
    }, [vinDealer])

    return (
        <div style={{height: '100%'}}>
            {/* top level dealer select */}
            <div>
                <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Dealer</InputLabel>
                        <Select
                        value={vinDealer}
                        onChange={handleChange}
                        label="Dealer"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>Cox Motors Demo</MenuItem>
                            <MenuItem value={2}>Marty Moose Motors!</MenuItem>
                            <MenuItem value={3}>Data Gateway Motors</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

        {/* 3 cards underneath */}
            <div style={{display: 'flex', height: '80%'}}>
                <div style={{width: '40%'}}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Users
                            </Typography>
                            <BasicTable users={dealerUsers} />
                            
                        </CardContent>
                        
                    </Card>
                </div>
                <div style={{width: '40%'}}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Contacts
                            </Typography>
                        </CardContent>
                        <BasicTableSearch users={foundUsers} />
                    </Card>
                </div>
                <div style={{width: '20%'}}>
                    <Card className={classes.leadCard}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Create Lead
                            </Typography>
                            
                            
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleOpen} size="small">CLICK HERE</Button>
                        </CardActions>
                    </Card>
                    <Card className={classes.leadCard}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Search for Contact
                            </Typography>
                            
                            
                        </CardContent>
                        <CardActions>
                            {/* <Button onClick={handleOpen} size="small">CLICK HERE</Button> */}
                            <Link style={{color: 'black'}} to="/demo/search">Search</Link>
                        </CardActions>
                        <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={{ top: '30%', left: '35%' }} className={classes.paper}>
                <TextField fullWidth label="First Name" variant="filled" onChange={handleFirst} value={first} />
                    <TextField fullWidth label="Last Name" variant="filled" onChange={handleLast} value={last} />
                    <TextField fullWidth label="Email" variant="filled" onChange={handleEmail} value={email} />
                    <TextField fullWidth label="Phone" variant="filled" onChange={handlePhone} value={phone} />
                    {/* <TextField fullWidth label="Vin" variant="filled" onChange={handleVin} value={vin} /> */}
                    <TextField
                        fullWidth
                        label="Notes"
                        multiline
                        variant="filled"
                        onChange={handleNotes}
                        value={notes}
                    />
                    {
                        loading ?
                            <h3>SENDING...</h3>
                            :
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={styles.button}
                                    onClick={submitLead}
                                >
                                    Create
                    </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{ backgroundColor: '#fff' }}
                                    onClick={handleClose}
                                >
                                    Cancel
                    </Button>

                            </div>

                    }
                </div>
            </Modal>
                        <Modal
                open={openError}
                onClose={handleErrorClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={{ top: '30%', left: '35%' }} className={classes.paper}>
                <h1>Error: {error}</h1>

                </div>
            </Modal>
                    </Card>
                </div>
            </div>
        </div>
    )
}