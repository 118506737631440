import React, { useEffect, useState } from 'react'
import { Paper, Button, MenuList, MenuItem } from "@material-ui/core";
import { Grid, GridItem, Box } from '@bigcommerce/big-design';

export const Dialog = (props) => {

    const [dialogs, setDialogs] = useState([])
    const [selectedDialog, setSelectedDialogs] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_BASE_URL}api/dash/dialog/standard`)
            .then(res => res.json())
            .then(jsnRes => {
                console.log(jsnRes.dialog_nodes)
                setLoading(false)
                setDialogs(jsnRes.dialog_nodes)
            })
    }, [])

    const onDialogSelect =  (obj) => {
        if(obj.output.text){
            setSelectedDialogs(obj.output.text.values)
        }
        if(obj.output.generic){
            let tempData = []
            obj.output.generic.forEach(x => {
                x.values.forEach(val => {
                    tempData.push(val.text)
                })
                 })
            setSelectedDialogs(tempData)
        }
    }


    // const handleSubmit = (inte, arryOfExamples, des) => {
    //     fetch(`${process.env.REACT_APP_BASE_URL}api/dash/create-intent`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({ intent: inte, examples: arryOfExamples, des })
    //     })
    //     console.log(inte, arryOfExamples)
    // }


    if (loading) return <h1>LOADING.....</h1>

    return (
        <Paper style={{ margin: 'auto' }}>
            <Grid gridColumns="repeat(2, 1fr)" style={{ padding: '2rem' }}>
                <GridItem>
                    <span>
                        Name your dialogs to match the use case
                    </span>
                    <Grid>
                        <GridItem>
                        </GridItem>
                        <GridItem>
                            <Button variant="contained" color="primary" >
                                Add Dialog
                            </Button >
                        </GridItem>
                    </Grid>
                    <MenuList >
                        {
                            dialogs.map((obj, idx) => {
                                return (
                                    <div>
                                    <MenuItem key={idx} button onClick={() => onDialogSelect(obj)} style={{ borderColor: "#989898", boxShadow: "inset 0 1px 1px #e5e5e5", maxWidth: '50%' }}
                                    >
                                        {obj.title}
                                    </MenuItem>
                                     </div>

                                )
                            })
                        }
                    </MenuList>
                </GridItem>
                <GridItem>
                    <div >
                        <div >
                            <span>
                                Add customer friendly responses.
                            </span>
                            <Grid>
                                <GridItem>
                                </GridItem>
                                <GridItem>
                                <Button variant="contained" color="primary" >
                                Add Response
                            </Button >
                                </GridItem>
                                <GridItem>
                                    <span style={{ fontWeight: 'bold' }}>
                                        (Pro tip: You can have multiple responses for each dialog)
                                         </span>
                                </GridItem>
                            </Grid>
                        </div>
                        <div>
                            <Box>
                                {
                                    selectedDialog.map((ex, idx) => {
                                        return (
                                            <div key={idx}>
                                                <p>{ex}</p>
                                            </div>
                                        )
                                    })

                                }
                            </Box>
                        </div>
                    </div>
                </GridItem>
            </Grid>
        </Paper>
    )
}