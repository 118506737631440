import React, { useState } from 'react'
import { Button, Modal, Backdrop, Fade, makeStyles } from '@material-ui/core';
import { CSVReader } from 'react-papaparse'
// import { Mapping } from './Mapping';
import { createGroups } from '../helperFunctions/helperFunctions';
import { Spinner } from '../Spinner/Spinner';
import { useSelector } from "react-redux";
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex'
  },
  exampleForm: {
    marginTop: 10
  },
  buttonContainer: {
    marginTop: 10
  }
}));

export const MassTradeQuote = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [parsedData, setData] = useState([])
  const [preIntent, setPreIntent] = useState([])
  const [fetchRdyData, SetFinalData] = useState({})
  const [loading, setLoading] = useState(false)
  const dealerId = useSelector(state => state.dealer.dealer);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnDrop = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
    let test = createGroups(data)
    console.log(test)
    setData(data)
    setPreIntent(test[1])
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    setData([])
    setPreIntent([])
    SetFinalData([])
    console.log('---------------------------')
  }

  const handlePairing = (actualIntent, preIntent, deleteFirst) => {

    SetFinalData((prevState) => {
      let copy = { ...prevState }
      if (deleteFirst) {
        let start = copy[deleteFirst].indexOf(parsedData[preIntent][0])
        copy[deleteFirst].splice(start, parsedData[preIntent].length)

      }
      copy[actualIntent] ? copy[actualIntent].push(...parsedData[preIntent]) : copy[actualIntent] = [...parsedData[preIntent]]

      return copy
    })

  }

  const handleMassUpdate = () => {
    setLoading(true)
    console.log(dealerId)
    axios.post(`${process.env.REACT_APP_NEEDLE_API}/csv-leads/mass-quote-system`, { data: parsedData, dealerId })
      .then(() => {
        setLoading(false)
        handleClose()
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Click to add CSV
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
            {preIntent.length > 0 &&
              <div style={{ maxHeight: '300px', overflow: 'auto', position: 'relative' }}>
                {loading ?
                  <Spinner />
                  :
                  <Button variant="contained" color="primary" onClick={handleMassUpdate}>BLAST AWAY!</Button>
                }
              </div>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  )
}