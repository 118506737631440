import React, {useRef, useEffect} from 'react'
import style from './ChatWindow.module.scss'
import {Text} from './Text'

export const ChatWindow = ({currentConversation, twilioNumber}) => {
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    console.log(messagesEndRef.current)
    if(messagesEndRef.current){
      messagesEndRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }
  useEffect(() => {
    scrollToBottom()
  }, [currentConversation]);

  return(
    <div className={style.container}>
      {
      currentConversation.length === 0 ?
        <h2 style={{ textAlign: 'center' }}>Select a chat to see it here</h2>
        :
        <div id='chat-container'>
          {
              currentConversation.map((text, idx) => (
                <Text twilioNumber={twilioNumber} key={idx} text={text} />
              ))
          }
          <div ref={messagesEndRef}/>
        </div>
    }
    </div>
  )
}