import React from 'react'
import { Button } from "@material-ui/core";

export const SetAppointment = (props) => {

  return (
    <Button
      onClick={props.onClick}
      disabled={!props.leadInfo}
      style={{ minWidth: '160px', maxWidth: '160px' }}
      variant="contained"
      size="large"
    >
      SET APPOINTMENT
    </Button>
  )
}