import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CSVReader } from 'react-papaparse'
export const MassLead = () => {
  
  const [allLeads, setLeads] = useState([])
  
  
  const handleOnDrop = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
    let createNewArray = []

    data.forEach(obj => {


        let tempObj = {
          full_name:  obj.data[12],
          email: obj.data[13],
          phone: obj.data[14].slice(-11),
          note: obj.data[16]
        }
       
        createNewArray.push(tempObj)

    })
    setLeads(createNewArray)
  }

  useEffect(()=>{

  }, [])

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }

  const handleClick = () => {
    console.log(allLeads)
    Axios.post('http://localhost:5000/csv-leads/send', allLeads)
    .then(results=> {
      console.log(results)
    })
  }
  return(
    <>
  <CSVReader
    onDrop={handleOnDrop}
    onError={handleOnError}
    addRemoveButton
    onRemoveFile={handleOnRemoveFile}
  >
    <span>Drop CSV file here or click to upload.</span>
  </CSVReader>
  <button onClick={handleClick}>Send</button>
  </>
  )
}