import React from "react";
import style from './ClientDetails.module.scss'
export const ClientDetails = (props) => {

  return (
    <div className={style.container}>
      <h1>Lead Information</h1>
      {
        props.leadInfo ?

          <div>
            <p>Name: {props.leadInfo.full_name}</p>
            <p>Phone Number: {props.leadInfo.phone}</p>
            <p>Email: {props.leadInfo.email}</p>
            <p>Dealership: {props.leadInfo.snap_auto_name}</p>
            <p>Lead Source: {props.leadInfo.leadType}</p>
            <div>
              {props.leadInfo.Trade_In_Vehicles.map((car, idx) => (
                <div>
                  <p>Vehicle Submitted #{idx + 1}</p>
                  <p>========================</p>
                  <p>Year: {car.year}</p>
                  <p>Make: {car.make}</p>
                  <p>Model: {car.model}</p>
                  <p>Vin: {car.vin}</p>
                  <p>Offer Low: {car.tradeNumbers.low}</p>
                  <p>Offer Mid: {car.tradeNumbers.target}</p>
                  <p>Offer High: {car.tradeNumbers.high}</p>
                  <p>Prospects Intention: {car.intention}</p>
                </div>
              )
              )
              }
            </div>
          </div>
          :

          <h1>Select a chat to see more</h1>
      }
    </div>
  )
}