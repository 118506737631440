/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, Modal } from "@material-ui/core";
import moment from "moment";
// Calendar Imports
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "auto",
    maxWidth: 400,
    minWidth: 350,
    maxHeight: 280,
    minHeight: 280,
    marginRight: 50,
    marginBottom: 50
  },
  modal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'min-content'
  },
  modalButton: {
    width: '100%'
  },
  content: {
    display: 'flex',
    justifyContent: 'center'
  },
  alert: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1'
  }
}));

export function CardCustom(props) {
  const classes = useStyles();
  const [notFound, setNotFound] = useState(false);
  const [convo, setConvo] = useState(null);
  const [alert, setAlert] = useState(false);

  const [customRange, setCustomRange] = useState(null);
  const [rangeDates, setRangeDates] = useState(null);
  const [calendar, setCalendar] = useState(false);

  const [thirtyLeads, set30Leads] = useState(null);
  const [fifteenLeads, set15Leads] = useState(null);
  const [mTDLeads, setMTDLeads] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [snapLead, setSnapLeads] = useState(null);
  const [snapWSLeads, setSnapWSLeads] = useState(null);
  const [appointmentsSet, setAppointments] = useState(null);
  const [landingpageLeads, setLandingLeads] = useState(null);
  const [mile, setMiles] = useState(null);
  const [lastClickedState, setLastClicked] = useState(null);
  const [lastBlockState, setLastBlock] = useState(null);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const {
    title,
    totalConvos,
    totalLeads,
    totalCustomers,
    origins,
    lastClicked,
    lastBlock,
    chatbotStatistics,
    dealer,
    snapLeads,
    wholesaleLeads,
    miles,
    appointments,
    landingLeads
  } = props;

  useEffect(() => {
    switch (title) {
      case "Custom Date Range":
        setCustomRange(dealer);
        break;
      case "Total Conversations":
        setConvo(totalConvos);
        break;
      case "Running 30 Days":
        const lead30Data = {...thirtyLeads}
        lead30Data.totalLeads = totalLeads
        set30Leads(lead30Data);
        break;
      case "Running 15 Days":
        const lead15Data = {...fifteenLeads}
        lead15Data.totalLeads = totalLeads
        set15Leads(lead15Data);
        break;
      case "Month to Date":
        const leadMTDData = {...mTDLeads}
        leadMTDData.totalLeads = totalLeads
        setMTDLeads(leadMTDData);
        break;
      case "SnapAuto Leads - MTD":
        setSnapLeads(snapLeads);
        setSnapWSLeads(wholesaleLeads);
        setLandingLeads(landingLeads)
        setMiles(miles);
        setAppointments(appointments)
        break;
      case "The Origins of Conversations":
        setOrigin(Object.entries(origins));
        break;
      case "Users Last Clicked":
        setLastClicked(Object.entries(lastClicked));
        break;
      case "Last Block":
        setLastBlock(Object.entries(lastBlock));
        break;
      default:
        setNotFound(true);
        break;
    }
  }, [title, totalConvos, totalLeads, origins, lastClicked, lastBlock, chatbotStatistics, dealer, snapLeads, wholesaleLeads, miles, appointments, landingLeads]);

  const closeCalendar = async () => {
    const startDate = dates[0].startDate.getTime()
    const endDate = dates[0].endDate.setHours(23,59,59,999)
    // Send Start Time and End Time to back-end
    try{
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/analytics/lead-data/${dealer}/${startDate}/${endDate}`)
      setRangeDates(res.data)
    }
    catch(e){
      setAlert(true)

      setTimeout(()=> {
        setAlert(false)
      }, 2500)
    }
    setCalendar(false)
  }

  return (
    <>
    <Card variant="outlined" className={classes.root}>
      {customRange && ( 
        <CardHeader
        title={title}
        subheader={rangeDates ? `Ranges from ${moment(dates[0].startDate).format('MMM Do YYYY')} to ${moment(dates[0].endDate).format('MMM Do YYYY')}` : ''}
      />)} 
      {thirtyLeads && (
        <CardHeader
          title={title}
          subheader={`From ${moment().subtract(30, 'days').format('MMM Do YYYY')}`}
      />)}
      {fifteenLeads && (
        <CardHeader
          title={title}
          subheader={`From ${moment().subtract(15, 'days').format('MMM Do YYYY')}`}
      />)}
      {mTDLeads && (
        <CardHeader
          title={title}
          subheader={moment().format('MMM')}
      />)}
      {title === "SnapAuto Leads - MTD" && (
        <CardHeader
          title={title}
          subheader={moment().format('MMM')}
      />)}
      <CardContent>
        {rangeDates && (
          <Typography variant="body2" color="textPrimary" component="h3">
            <p>Total Leads sent to dealers CRM: {rangeDates.leads}</p>
            <p>Total Conversations: {rangeDates.users}</p>
            <><hr/></>
            Lead Conversion: {rangeDates.users ? `${Math.round((rangeDates.leads / rangeDates.users).toFixed(2) * 100)}%` : 'No Data Available'}
            <><hr/></>
          </Typography>
        )}
        {convo && (
          <Typography variant="body2" color="textPrimary" component="h3">
            Total Conversations: {convo}
          </Typography>
        )}
        {thirtyLeads && (
          <Typography variant="body2" color="textPrimary" component="h3">
            <p>Total Leads sent to dealers CRM: {thirtyLeads.totalLeads}</p>
            <p>Total Conversations: {totalCustomers}</p>
            <><hr/></>
            Lead Conversion: {totalCustomers ? `${Math.round((thirtyLeads.totalLeads / totalCustomers).toFixed(2) * 100)}%` : 'No Data Available'}
          </Typography>
        )}
        {fifteenLeads && (
          <Typography variant="body2" color="textPrimary" component="h3">
            <p>Total Leads sent to dealers CRM: {fifteenLeads.totalLeads}</p>
            <p>Total Conversations: {totalCustomers}</p>
            <><hr/></>
            Lead Conversion: {totalCustomers ? `${Math.round((fifteenLeads.totalLeads / totalCustomers).toFixed(2) * 100)}%` : 'No Data Available'}
          </Typography>
        )}
        {mTDLeads && (
          <Typography variant="body2" color="textPrimary" component="h3">
            <p>Total Leads sent to dealers CRM: {totalLeads}</p>
            <p>Total Conversations: {totalCustomers}</p>
            <><hr/></>
            Lead Conversion: {totalCustomers ? `${Math.round((totalLeads / totalCustomers).toFixed(2) * 100)}%` : 'No Data Available'}
          </Typography>
        )}
        {title === "SnapAuto Leads - MTD" && (
          <Typography variant="body2" color="textPrimary" component="h3">
            <p>SnapAuto Leads: {snapLead}</p>
            <p>Landing Page Leads: {landingpageLeads}</p>
            <p>Wholesale Leads: {snapWSLeads}</p>
            <p>Appointments Set: {appointmentsSet}</p>
            <p>Mileage Threshold: {mile}</p>
          </Typography>
        )}
        {customRange && (
          <div className={classes.content}><Button
            variant="contained"
            color="primary"
            size="large"
            disabled={false}
            onClick={()=>setCalendar(true)}
            >
              Select Date Range</Button>
          </div>
         
          
        )}
        {origin && (
          <Typography variant="body2" color="textPrimary" component="div">
            {origin.map((el, i) => {
              return (
                <p key={i}>
                  {el[0]}: {el[1]}
                </p>
              );
            })}
          </Typography>
        )}
        {lastBlockState && (
          <Typography variant="body2" color="textPrimary" component="div">
            {lastBlockState.map((el, i) => {
              return (
                <p key={i}>
                  {el[0]}: {el[1]}
                </p>
              );
            })}
          </Typography>
        )}
        {lastClickedState && (
          <Typography variant="body2" color="textPrimary" component="div">
            {lastClickedState.map((el, i) => {
              return (
                <p key={i}>
                  {el[0]}: {el[1]}
                </p>
              );
            })}
          </Typography>
        )}
        {notFound && (
          <Typography variant="body2" color="textPrimary" component="h1">
            Sorry nothing was found!
          </Typography>
        )}
      </CardContent>
    </Card>
    {calendar && (
      <Modal
        open={true}
        onClose={closeCalendar}>
          <div className={classes.modal}>
        <DateRangePicker
          ranges={dates}
          onChange={item => setDates([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          direction="horizontal"
        ></DateRangePicker>
        <Button 
              className={classes.modalButton}
              variant="contained"
              color="primary"
              size="large"
              onClick={closeCalendar}>Update Dates</Button>
        </div>
      </Modal>
    )}
    {alert && (
      <Alert className={classes.alert} severity="error">There was an issue retrieving the dates! Please try again!</Alert>
    )}
    </>
  );
}
