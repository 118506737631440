import axios from "axios";


export async function login(email, password) {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
    email,
    password
  });
}

export async function register(email, fullname, password) {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
    email,
    fullname,
    password
  });
}

export function requestPassword(email, password=null) {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, { email, password });
}

export function getUserByToken(jwt) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${process.env.REACT_APP_BASE_URL}/auth/current`, {
    headers: { Authorization: jwt }
  });
}
