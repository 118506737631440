import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

export const BasicTableSearch = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          props.users.contacts ?
          props.users.contacts.map((row) => (
            <TableRow key={row.ContactId}>
              <TableCell>{row.ContactInformation.FirstName}</TableCell>
              <TableCell component="th" scope="row">
                {row.ContactInformation.LastName}
              </TableCell>
              <TableCell>{row.ContactInformation.Emails[0].EmailAddress}</TableCell>
              <TableCell>{row.ContactInformation.Phones[0].Number}</TableCell>
              <TableCell>{row.ContactInformation.Note}</TableCell>
            </TableRow>
          ))
            :
            ''
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}