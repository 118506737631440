import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as dealer from "../../../src/_metronic/ducks/dealers";
import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import * as activechat from "../pages/home/bigcommerce/Chats/redux/ducks/Activechat";
import * as loader from "../../../src/_metronic/ducks/loader"
export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  dealer: dealer.reducer,
  activechat: activechat.reducer,
  loader: loader.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
