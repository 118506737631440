export async function replaceHeader(data, replace_id, replaceFFL, cbF) {
  let arry = [];

  for (let i = 0; i < data.length; i++) {
    let obj = {};
    if (data[i][replace_id] || data[i][replace_id] === "") {
      obj["_id"] = data[i][replace_id];
      // delete data[i][replace_id];
      // arry.push(data[i]);
    }
    if (data[i][replaceFFL] || data[i][replaceFFL] === "") {
      obj["ffl_required"] = data[i][replaceFFL];
      // delete data[i][replaceFFL];
      arry.push(obj);
    } else {
      arry.push(obj);
    }
  }
  cbF(arry);
}

export function grabAllUniqKeys(arry) {
  let keys = arry
    .map(obj => {
      return Object.keys(obj);
    })
    .flat();
  return Array.from(new Set(keys));
}

export function createStringQuery(obj) {
  let objC = { ...obj };
  let query = [];
  for (let key in objC) {
    if (!objC[key]) {
      // console.log(objC[key])
      delete objC[key];
    } else {
      query.push(`${key}=${objC[key]}`);
    }
  }
  return query.join("&");
}

export function createGroups(arr){
  let newObj = {}
  for(let i = 1; i < arr.length; i++){
    let intent = arr[i].data[3]
    if(intent === undefined) continue
    if(intent === "") {
      intent = 'empty'
    }
    // .replace(" ", "").toLowerCase()
    let message = arr[i].data[0]
    newObj[intent] ? newObj[intent].push(message) : newObj[intent] = [message]
  }
  let keys = Object.keys(newObj)
  return [newObj, keys]
}

export async function customerWithOrders(customerArray, ordersArray) {
  let newCustomers = [];
  await customerArray.forEach(obj => {
    let tempCust = null;
    ordersArray.forEach(obj2 => {
      if (
        obj._id === obj2.customer._id ||
        obj.email === obj2.customer.email ||
        obj.id === obj2.customer.id
      ) {
        obj.orders
          ? (obj.orders = [...obj.orders, obj2])
          : (obj.orders = [obj2]);
        tempCust = obj;
      }
    });
    tempCust ? newCustomers.push(tempCust) : (tempCust = null);
  });
  return newCustomers;
}

export function hasSubscriptions(custArr, segOrderArr) {
  let obj = {};
  custArr.forEach(x => {
    let status = false;
    segOrderArr.forEach(y => {
      if (x._id === y._id) {
        for (let i = 0; i < y.orders.length; i++) {
          if (y.orders[i].status === "Subscribed") {
            status = true;
            obj[x._id] = status;
            status = false;
            break;
          }
        }
      }
    });
  });

  return obj;
}

export function makeDataObj(data) {
  const planObj = createPlanObjLoop(data);
  const [orderDateObj, years] = createDateObjLoop(data);
  const sortedPlanObj = Object.entries(planObj).sort((a, b) =>
    b[0].localeCompare(a[0])
  );

  const chartData = makeChartData(planObj);

  console.log(orderDateObj);

  return [sortedPlanObj, chartData, orderDateObj, years];
}

function createPlanObjLoop(data) {
  const planObj = {};
  for (let i = 0; i < data.orders.length; i++) {
    if (data.orders[i].paid) {
      if (planObj[data.orders[i].plans.sku]) {
        planObj[data.orders[i].plans.sku].amount++;
        planObj[data.orders[i].plans.sku].total += parseFloat(
          data.orders[i].amount_due.slice(1)
        );
      } else {
        planObj[data.orders[i].plans.sku] = {
          amount: 1,
          image: data.orders[i].plans.image,
          name: data.orders[i].plans.name,
          total: parseFloat(data.orders[i].amount_due.slice(1))
        };
      }
    }
  }
  return planObj;
}

function createDateObjLoop(data) {
  const orderDateObj = {};
  const dataYears = [];

  data.orders.forEach(obj => {
    if (obj.paid_on) {
      let dateSplit = obj.paid_on.split("/");
      if (!dataYears.includes(dateSplit[2])) {
        dataYears.push(dateSplit[2]);
      }
    }
  });
  dataYears.forEach(el => {
    for (let i = 0; i < data.orders.length; i++) {
      if (data.orders[i].paid_on) {
        let orderDate = data.orders[i].paid_on.split("/");
        if (el === orderDate[2]) {
          if (!orderDateObj[el]) {
            orderDateObj[el] = {
              breakdown: {
                [orderDate[0]]: {
                  [data.orders[i].plans.sku]: {
                    amt: 1,
                    total: parseFloat(data.orders[i].amount_due.slice(1))
                  }
                }
              },
              totals: {}
            };
          } else {
            if (!orderDateObj[el].breakdown[orderDate[0]]) {
              orderDateObj[el].breakdown[orderDate[0]] = {
                [data.orders[i].plans.sku]: {
                  amt: 0,
                  total: parseFloat(data.orders[i].amount_due.slice(1))
                }
              };
            }
            if (
              orderDateObj[el].breakdown[orderDate[0]][data.orders[i].plans.sku]
            ) {
              orderDateObj[el].breakdown[orderDate[0]][data.orders[i].plans.sku]
                .amt++;
              orderDateObj[el].breakdown[orderDate[0]][
                data.orders[i].plans.sku
              ].total += parseFloat(data.orders[i].amount_due.slice(1));
            } else {
              orderDateObj[el].breakdown[orderDate[0]][
                data.orders[i].plans.sku
              ] = {
                amt: 1,
                total: parseFloat(data.orders[i].amount_due.slice(1))
              };
            }
          }
        }
      }
    }
  });
  dataYears.forEach(el => {
    let tempObj = {};
    let monthsAvl = [];
    let tempArry = Object.entries(orderDateObj[el].breakdown);
    tempArry.forEach(el2 => {
      monthsAvl.push(el2[0]);
      let makeLoop = Object.entries(el2[1]);
      makeLoop.forEach(el3 => {
        if (tempObj[el3[0]]) {
          tempObj[el3[0]].amt += el3[1].amt;
          tempObj[el3[0]].total += parseFloat(el3[1].total);
        } else {
          tempObj[el3[0]] = { amt: el3[1].amt, total: el3[1].total };
        }
      });
    });
    orderDateObj[el].totals = tempObj;
    orderDateObj[el].chartData = makeChartData(tempObj);
    orderDateObj[el].availableMonths = monthsAvl;
  });

  return [orderDateObj, dataYears];
}

export function makeChartData(obj) {
  const sortedObj = Object.entries(obj).sort((a, b) =>
    b[0].localeCompare(a[0])
  );

  const chartData = sortedObj.map((arr, idx) => {
    return { label: arr[0], y: parseFloat(arr[1].total.toFixed(2)), x: idx };
  });
  return chartData;
}
