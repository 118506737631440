export const dealersAvailable = [
  {
      "DealerId": 1,
      "Name": "Cox Motors Demo",
      "City": "Mission",
      "State": "KS"
  },
  {
      "DealerId": 6082,
      "Name": "Marty Moose Motors!",
      "City": "Mission",
      "State": "KS"
  },
  {
      "DealerId": 12617,
      "Name": "Data Gateway Motors",
      "City": "Mission",
      "State": "KS"
  }
]

export const dealerOneUser = [
  {
    "UserGroup": "Sales Force",
    "UserTypes": [
        null,
        null,
        null
    ],
    "EmailAddress": "dave@test.vinleads.com",
    "FullName": "Dave Watson",
    "FirstName": "Dave",
    "LastName": "Watson",
    "UserId": 9,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "Jeff Wolfer",
    "FirstName": "Jeff",
    "LastName": "Wolfer",
    "UserId": 5724,
    "IlmAccess": "Manager"
},
{
    "UserGroup": "East Finance Team Nick",
    "UserTypes": [
        null
    ],
    "EmailAddress": "gensales@test.vinleads.com",
    "FullName": "Generic Sales",
    "FirstName": "Generic",
    "LastName": "Sales",
    "UserId": 9700,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "managersarein@test.vinleads.com",
    "FullName": "Generic Manager",
    "FirstName": "Generic",
    "LastName": "Manager",
    "UserId": 9701,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "services@test.vinleads.com",
    "FullName": "Generic Administrator",
    "FirstName": "Generic",
    "LastName": "Administrator",
    "UserId": 9702,
    "IlmAccess": "Manager"
},
{
    "UserGroup": "SSO/Bridge Testing",
    "UserTypes": [],
    "EmailAddress": "tdouglas@test.vinleads.com",
    "FullName": "Travis Douglas",
    "FirstName": "Travis",
    "LastName": "Douglas",
    "UserId": 10331,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [
        null
    ],
    "EmailAddress": "donna@test.vinleads.com",
    "FullName": "Donna Receptionist",
    "FirstName": "Donna",
    "LastName": "Receptionist",
    "UserId": 13315,
    "IlmAccess": "None"
},
]

export const dealerTwoUser = [
  {
    "UserGroup": "Sales Force",
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "Dave Watson",
    "FirstName": "Dave",
    "LastName": "Watson",
    "UserId": 9,
    "IlmAccess": "SalesPerson"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "David Tinsley-SEO",
    "FirstName": "David",
    "LastName": "Tinsley-SEO",
    "UserId": 14045,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "ltest001@martymoose.motosnap.com",
    "FullName": "Load001 Test001",
    "FirstName": "Load001",
    "LastName": "Test001",
    "UserId": 110889,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "@dwnissan.com",
    "FullName": "Load006 Test006",
    "FirstName": "Load006",
    "LastName": "Test006",
    "UserId": 110894,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [
        null,
        null
    ],
    "EmailAddress": "",
    "FullName": "Mobile Salesperson",
    "FirstName": "Mobile",
    "LastName": "Salesperson",
    "UserId": 123451,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "mobilemanager@martymoose.motosnap.com",
    "FullName": "Mobile Manager",
    "FirstName": "Mobile",
    "LastName": "Manager",
    "UserId": 124099,
    "IlmAccess": "Manager"
},
{
    "UserGroup": "Admins",
    "UserTypes": [
        null,
        null
    ],
    "EmailAddress": "gsparks1@martymoose.motosnap.com",
    "FullName": "Gabriel Sparks",
    "FirstName": "Gabriel",
    "LastName": "Sparks",
    "UserId": 129253,
    "IlmAccess": "Admin"
},
{
    "UserGroup": "sales",
    "UserTypes": [
        null,
        null,
        null
    ],
    "EmailAddress": "abetest1@martymoose.motosnap.com",
    "FullName": "Abe Tester",
    "FirstName": "Abe",
    "LastName": "Tester",
    "UserId": 173653,
    "IlmAccess": "SalesPerson"
}
]

export const dealerThreeUser = [
  {
    "UserGroup": null,
    "UserTypes": [
        null,
        null
    ],
    "EmailAddress": "",
    "FullName": "ICARX Data Gateway",
    "FirstName": "ICARX",
    "LastName": "Data Gateway",
    "UserId": 464559,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [
        null,
        null
    ],
    "EmailAddress": "",
    "FullName": "CloudOne Data Gateway",
    "FirstName": "CloudOne",
    "LastName": "Data Gateway",
    "UserId": 471235,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "Aaron Holthaus",
    "FirstName": "Aaron",
    "LastName": "Holthaus",
    "UserId": 753043,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "Anjali Kotthapally",
    "FirstName": "Anjali",
    "LastName": "Kotthapally",
    "UserId": 753044,
    "IlmAccess": "SalesPerson"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "Topher Birth",
    "FirstName": "Topher",
    "LastName": "Birth",
    "UserId": 753045,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "Jason Jones",
    "FirstName": "Jason",
    "LastName": "Jones",
    "UserId": 753046,
    "IlmAccess": "SalesPerson"
},
{
    "UserGroup": null,
    "UserTypes": [],
    "EmailAddress": "",
    "FullName": "CallRevu App",
    "FirstName": "CallRevu",
    "LastName": "App",
    "UserId": 753048,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    ],
    "EmailAddress": "",
    "FullName": "Test User",
    "FirstName": "Test",
    "LastName": "User",
    "UserId": 759500,
    "IlmAccess": "Admin"
},
{
    "UserGroup": null,
    "UserTypes": [
        null
    ],
    "EmailAddress": "",
    "FullName": "Toyota Sales Representative",
    "FirstName": "Toyota Sales",
    "LastName": "Representative",
    "UserId": 782357,
    "IlmAccess": "SalesPerson"
},
{
    "UserGroup": null,
    "UserTypes": [
        null
    ],
    "EmailAddress": "",
    "FullName": "Toyota Manager",
    "FirstName": "Toyota",
    "LastName": "Manager",
    "UserId": 782379,
    "IlmAccess": "Manager"
},
{
    "UserGroup": null,
    "UserTypes": [
        null
    ],
    "EmailAddress": "",
    "FullName": "AIQ Sales",
    "FirstName": "AIQ",
    "LastName": "Sales",
    "UserId": 784937,
    "IlmAccess": "Admin"
},

]