import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonContainer:{
    marginTop: 10
  }
}));

export const ExampleAdd = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [intentNameState, setIntentName] = useState();
  const [example1, setExample1] = useState();
  const [example2, setExample2] = useState();
  const [example3, setExample3] = useState();
  const [example4, setExample4] = useState();
  const [example5, setExample5] = useState();

  const {intentName} = props

  useEffect(()=>{
    setIntentName(intentName)
  }, [intentName])

  const makeExample = () => {
    if(intentNameState && example1 ){
      setOpen(false)
      setExample1()
      setExample2()
      setExample3()
      setExample4()
      setExample5()
      props.updateIntent(intentNameState, [example1, example2, example3, example4, example5])
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {props.title}
      </Button>
      <Modal

        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Please Provide new example(s) to {intentName}</h2>
            <Input
            disabled
              fullWidth
              value={intentNameState}
              name="intent"
              onChange={(e) => setIntentName(e.target.value)}
              placeholder="Intent to update"

            />
            <Input
              fullWidth
              value={example1}
              name="example"
              onChange={(e) => setExample1(e.target.value)}
              placeholder="Name of new Example"

            />
            <Input
              fullWidth
              value={example2}
              name="example"
              onChange={(e) => setExample2(e.target.value)}
              placeholder="Name of new Example"

            />
            <Input
              fullWidth
              value={example3}
              name="example"
              onChange={(e) => setExample3(e.target.value)}
              placeholder="Name of new Example"

            />
            <Input
              fullWidth
              value={example4}
              name="example"
              onChange={(e) => setExample4(e.target.value)}
              placeholder="Name of new Example"

            />
            <Input
              fullWidth
              value={example5}
              name="example"
              onChange={(e) => setExample5(e.target.value)}
              placeholder="Name of new Example"

            />
            <div className={classes.buttonContainer}>
              <Button onClick={makeExample} variant="contained" color="primary">Add Examples</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
