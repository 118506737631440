import React from 'react'
import { Search } from "../../components/search/Search";


export const UsersSearch = () => {

    return (
        <div>
            <Search />
        </div>
    )
}