import React, { useEffect, useState } from 'react'
import styles from './Container.module.scss'
import { Messages } from './Messages/Messages'

import { SendBox } from './SendBox/SendBox';
import axios from 'axios';
import { ChatWindow } from './ChatWindow/ChatWindow';
import openSocket from 'socket.io-client'
import { ClientDetails } from './ClientDetails/ClientDetails';

export const ClientChats = (props) => {

  const [selectedChatNumber, setSelectedChat] = useState(null)
  const [currentConversation, setCurrentConversation] = useState([])
  const [twilioNumber, setTwilioNumber] = useState(null)
  const [newMessage, setNewMessage] = useState(null)
  const [newSentMessage, setNewSentMessage] = useState(null)
  const [newConversation, setNewConversation] = useState(null)
  const [leadInfo, setLeadInfo] = useState(null)
  // const dealerId = useSelector(state => state.dealer.dealer);
  // const dealerId = '9285'
  const dealerId = props.match.params.id
  // useEffect(() => {
  // axios.post('https://twilio-server-snap.herokuapp.com/webhook/getProspects', { dealerId: dealerId })
  //   .then(data => {
  //     console.log(data)
  //     setAllMessages(data.data.conversations)
  //     setTwilioNumber(data.data.twilio_number)
  // setTwilioNumber('+12019375215')
  //   })
  //   setSelectedChat('+12019375215')
  //   const socket = openSocket(`https://twilio-server-snap.herokuapp.com/`)
  //   socket.on('incoming-text-message', data => {
  //     setNewMessage(data)
  //   })

  //   return () => socket.disconnect()
  // }, [])

  useEffect(() => {
    const socket = openSocket(`https://twilio-server-snap.herokuapp.com/`)
    // const socket = openSocket(`http://localhost:5050/`)
    setTwilioNumber(props.match.params.twilio)
    setSelectedChat(props.match.params.phone)
    socket.on('incoming-text-message', data => {
      if (data.action === 'newIncomingMessage') {
        setNewMessage(data)
      } else if (data.action === 'newIncomingConversation') {
        setNewConversation(data)
      } else if (data.action === 'outgoingMessage') {
        setNewSentMessage(data)
      }
    })

    return () => socket.disconnect()

  }, [])

  // const handleConvoChange = (selectedNumber) => {
  //   setSelectedChat(selectedNumber)
  // }

  useEffect(() => {
    if (selectedChatNumber) {
      axios.post('https://twilio-server-snap.herokuapp.com/webhook/getCurrentConversation', { dealerId: dealerId, selectedChatNumber })
        // axios.post('http://localhost:5050/webhook/getCurrentConversation', { dealerId: dealerId, selectedChatNumber })
        .then(data => {
          setCurrentConversation(data.data.prospect.texts)
          setLeadInfo(data.data.leadInfo)
          // setAllMessages(prev => {
          //   const copyConvo = [...prev]
          //   const foundObjIdx = copyConvo.findIndex(obj => obj.user_number === data.data.prospect.user_number)
          //   const objCopy = { ...copyConvo[foundObjIdx] }
          //   objCopy.newMessage = false
          //   copyConvo[foundObjIdx] = objCopy
          //   return copyConvo
          // })
        })
    }

  }, [selectedChatNumber])

  useEffect(() => {
    if (newMessage) {
      if (newMessage.user === selectedChatNumber) {
        setCurrentConversation(prev => [...prev, newMessage.newText])
        setNewMessage(null)
      } else {
        // setAllMessages(prev => {
        //   const copyConvo = [...prev]
        //   const foundObjIdx = copyConvo.findIndex(obj => obj.user_number === newMessage.user)
        //   const objCopy = { ...copyConvo[foundObjIdx] }
        //   objCopy.newMessage = true
        //   copyConvo[foundObjIdx] = objCopy
        //   return copyConvo
        // })
        console.log('i am here to see')
      }
    }
  }, [newMessage])

  useEffect(() => {
    if (newSentMessage) {
      if (newSentMessage.newText.to === selectedChatNumber) {
        setCurrentConversation(prev => [...prev, newSentMessage.newText])
        setNewSentMessage(null)
      }
    }

  }, [newSentMessage])


  return (
    <>

          <div className={styles.container}>
            <div className={styles.middleColumn}>
              <div className={styles.top}>
                <ChatWindow twilioNumber={twilioNumber} currentConversation={currentConversation} />
              </div>
              <div className={styles.bottom}>
                <SendBox twilioNumber={twilioNumber} to={selectedChatNumber} />
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.top}>
                <ClientDetails leadInfo={leadInfo} />
              </div>
            </div>
          </div>

    </>
  )
}