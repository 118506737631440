/* eslint-disable no-restricted-imports */
import React, {useState} from 'react'
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import {saveAs} from 'file-saver'
import axios from 'axios'
export const MatchBackButton = () => {

  const dealerId = useSelector(state => state.dealer.dealer);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)


  const handleSubmit = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_NEEDLE_API}/match-back/report?dealerID=${dealerId}`, { responseType: 'arraybuffer' })
    .then(response => {
      const blob = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `MatchBack ${new Date().toISOString()}.xlsx`)
      setLoading(false)
    })
        .catch(err => {
            setLoading(false)
            setError(true)
        })
};


  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{marginLeft: 10, marginBottom: 10}}
      disabled={loading}
      onClick={handleSubmit}
    >
      {loading ? 'Loading... ': "MatchBack Report"}
    </Button>
  )
}