import React, { useEffect, useState, useContext } from "react";
import { ProfileSide } from "./ProfileSide/ProfileSide";
import { DataSide } from "./DataSide/DataSide";
import { CustomerContext } from "../../../../../context/customerWProducts";
import styles from "./CustomerProfile.module.scss";

export const CustomerProfile = props => {
  const customerObj = useContext(CustomerContext);

  const [currentPro, setCurrent] = useState(false);
  const [subs, setSubs] = useState(false);

  useEffect(() => {
    if (customerObj.customer) {
      setCurrent(customerObj.customer);
      customerObj.customer.subscriptions &&
        setSubs(customerObj.customer.subscriptions);
    } else {
      props.history.push("/customers/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.pageContainer}>
      <ProfileSide currentPro={currentPro} />
      <DataSide subs={subs} />
    </div>
  );
};
