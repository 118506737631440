import React, { useState } from "react";
import {Form, Button, Container, Row, Col} from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert';

import {LinearProgress} from '@material-ui/core';
import { onboardNewClient } from '../../../../crud/cc.api'


export const Onboarding = props => {
const [loading, isLoading] = useState(false)
const [alert, isAlert] = useState(false)
const [alertMessage, setAlertMessage] = useState('')
const [disableButton, isDisabled] = useState(false)
const [alertType, setAlertType] = useState('')

  const submit = async (e) => {
    isLoading(true)
    isDisabled(true)  
    e.preventDefault()

    const rawFormData = e.target.elements;
    const formData = {
        'buyers_bridge_id': rawFormData.buyers_bridge_id.value,
        'fb_app_id': rawFormData.fb_app_id.value,
        'fb_token': rawFormData.fb_token.value,
        'PSID' : rawFormData.PSID.value,
        'name' : rawFormData.name.value,
        'website' : rawFormData.website.value,
        'twilio_number' : rawFormData.twilio_number.value,
        'bot_id' : rawFormData.bot_id.value,
        'adf_email' : rawFormData.adf_email.value,
        'contact_email' : rawFormData.contact_email.value,
        'address' : rawFormData.address.value,
    }

    try{
        await onboardNewClient(formData)
                
        isAlert(true)
        setAlertMessage('New Client Onboarded Successfully!')
        setAlertType('success')
        isDisabled(false)
        isLoading(false)
    }catch(e){
        isAlert(true)
        setAlertMessage('Error Onboarding Client!')
        setAlertType('error')
        isDisabled(false)
        isLoading(false)
        console.log(e)
    }
  }
    return (
    <Container>    
          <Form onSubmit={submit}>
            <Row>
              <Col>
                <Form.Group controlId="buyers_bridge_id">
                    <Form.Label>Buyers Bridge ID</Form.Label>
                    <Form.Control type="text" placeholder=""/>
                    <Form.Text className="text-muted">
                    Buyers Bridge ID
                    </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="fb_app_id">
                    <Form.Label>FB App ID</Form.Label>
                    <Form.Control type="text" placeholder="" required/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="fb_token">
                    <Form.Label>FB Token</Form.Label>
                    <Form.Control type="text" placeholder="" required/>
                    <Form.Text className="text-muted">
                    Facebook Page Token
                    </Form.Text>
                </Form.Group>
              </Col>    
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="PSID">
                    <Form.Label>PSID</Form.Label>
                    <Form.Control type="text" placeholder="" required/>
                    <Form.Text className="text-muted">
                    Facebook Page PSID
                    </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="" required/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control type="url" placeholder=""/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="twilio_number">
                    <Form.Label>Twilio #</Form.Label>
                    <Form.Control type="text" placeholder=""/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="bot_id">
                    <Form.Label>Bot ID</Form.Label>
                    <Form.Control type="text" placeholder="" required/>
                    <Form.Text className="text-muted">
                    Chatbot Bot ID
                    </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="adf_email">
                    <Form.Label>ADF Email</Form.Label>
                    <Form.Control type="email" placeholder="" required/>
                    <Form.Text className="text-muted">
                    Email for geniune leads
                    </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="contact_email">
                    <Form.Label>Contact Email</Form.Label>
                    <Form.Control type="email" placeholder="" required/>
                    <Form.Text className="text-muted">
                    Email for red-leads, spanish, etc. 
                    </Form.Text>
                </Form.Group>
              </Col> 
              <Col>
                <Form.Group controlId="address">
                    <Form.Label>Full Address</Form.Label>
                    <Form.Control type="text" placeholder="" required/>
                    <Form.Text className="text-muted">
                    Full Address including City, State, and Zipcode
                    </Form.Text>
                </Form.Group>
              </Col> 
            </Row>
            <Button variant="primary" type="submit" disabled={disableButton}>
                Submit
            </Button>
            </Form>
            {loading ? <LinearProgress></LinearProgress> : ''}
        {alert ? <Alert severity={alertType}>{alertMessage}</Alert> : ''}
    </Container>
    );
};
