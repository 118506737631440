import React, { useEffect, useState } from 'react'
import styles from './Container.module.scss'
import { Messages } from './Messages/Messages'
import { useSelector } from "react-redux";
import { SendBox } from './SendBox/SendBox';
import axios from 'axios';
import { ChatWindow } from './ChatWindow/ChatWindow';
import openSocket from 'socket.io-client'
import { ClientDetails } from './ClientDetails/ClientDetails';
import { ActionContainer } from './ActionButtons/ButtonContainer';
import { Modal, Button, TextField, Fade, makeStyles, Tabs, Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex'
  }
}));

export const Container = (props) => {
  const classes = useStyles();
  const [allMessages, setAllMessages] = useState([])
  const [completedMessages, setCompletedMessages] = useState([])
  const [selectedChatNumber, setSelectedChat] = useState(null)
  const [currentConversation, setCurrentConversation] = useState([])
  const [twilioNumber, setTwilioNumber] = useState(null)
  const [newMessage, setNewMessage] = useState(null)
  const [newSentMessage, setNewSentMessage] = useState(null)
  const [newConversation, setNewConversation] = useState(null)
  const [leadInfo, setLeadInfo] = useState(null)
  const [adfEmail, setAdfEmail] = useState(null)
  const [open, setOpen] = useState(false);
  const [appointmentDate, setAppointment] = useState('')
  const [tabValue, setTableValue] = useState(0)
  const dealerId = useSelector(state => state.dealer.dealer);
  // const dealerId = '9285'
  useEffect(() => {
    setSelectedChat(null)
    setLeadInfo(null)
    setCurrentConversation([])
    if (dealerId) {
      axios.post('https://twilio-server-snap.herokuapp.com/webhook/getProspects', { dealerId: dealerId })
        // axios.post('http://localhost:5050/webhook/getProspects', { dealerId: dealerId })
        .then(data => {
          console.log(data)
          setAllMessages(data.data.conversations)
          setTwilioNumber(data.data.twilio_number)
          setCompletedMessages(data.data.conversationsCompleted)
        })

    }
  }, [dealerId])

  useEffect(() => {
    const socket = openSocket(`https://twilio-server-snap.herokuapp.com/`)
    // const socket = openSocket(`http://localhost:5050/`)
    socket.on('incoming-text-message', data => {
      if (data.action === 'newIncomingMessage') {
        setNewMessage(data)
      } else if (data.action === 'newIncomingConversation') {
        setNewConversation(data)
      } else if (data.action === 'outgoingMessage') {
        setNewSentMessage(data)
      }
    })

    return () => socket.disconnect()

  }, [])

  const handleConvoChange = (selectedNumber) => {
    setSelectedChat(selectedNumber)
  }

  useEffect(() => {
    if (selectedChatNumber) {
      axios.post('https://twilio-server-snap.herokuapp.com/webhook/getCurrentConversation', { dealerId: dealerId, selectedChatNumber })
        // axios.post('http://localhost:5050/webhook/getCurrentConversation', { dealerId: dealerId, selectedChatNumber })
        .then(data => {
          setCurrentConversation(data.data.prospect.texts)
          setLeadInfo(data.data.leadInfo)
          setAdfEmail(data.data.adf_email)
          setAllMessages(prev => {
            const copyConvo = [...prev]
            const foundObjIdx = copyConvo.findIndex(obj => obj.user_number === data.data.prospect.user_number)
            const objCopy = { ...copyConvo[foundObjIdx] }
            objCopy.newMessage = false
            copyConvo[foundObjIdx] = objCopy
            return copyConvo
          })
        })
    }

  }, [selectedChatNumber])

  useEffect(() => {
    if (newMessage) {
      if (newMessage.user === selectedChatNumber) {
        setCurrentConversation(prev => [...prev, newMessage.newText])
        setNewMessage(null)
      } else {
        setAllMessages(prev => {
          const copyConvo = [...prev]
          const foundObjIdx = copyConvo.findIndex(obj => obj.user_number === newMessage.user)
          const objCopy = { ...copyConvo[foundObjIdx] }
          objCopy.newMessage = true
          copyConvo[foundObjIdx] = objCopy
          return copyConvo
        })

      }
    }
  }, [newMessage])

  useEffect(() => {
    if (newSentMessage) {
      if (newSentMessage.newText.to === selectedChatNumber) {
        setCurrentConversation(prev => [...prev, newSentMessage.newText])
        setNewSentMessage(null)
      }
    }

  }, [newSentMessage])

  useEffect(() => {
    if (newConversation) {
      console.log(newConversation)

      setAllMessages(prev => [newConversation.data.data, ...prev])
      setNewConversation(null)

    }
  }, [newConversation])

  const submitLead = () => {
    axios.post('https://twilio-server-snap.herokuapp.com/send-lead/chat', { dealerId: dealerId, leadInfo, currentConversation, adfEmail })
      // axios.post('http://localhost:5050/send-lead/chat', { dealerId: dealerId, leadInfo, currentConversation, adfEmail })
      .then(data => {
        console.log(data)
        setAllMessages(prev => {
          const copyConvo = [...prev]
          const foundObjIdx = copyConvo.findIndex(obj => obj.user_number === data.data.goodPhone)
          const objCopy = { ...copyConvo[foundObjIdx] }
          objCopy.completed = true
          copyConvo[foundObjIdx] = objCopy
          return copyConvo
        })
        setSelectedChat(null)
        setLeadInfo(null)
        setCurrentConversation([])
      })
  }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const submitAppointmentLead = () => {
    axios.post('https://twilio-server-snap.herokuapp.com/send-lead/appointment-lead-chat', { appointment: appointmentDate, dealerId: dealerId, leadInfo, currentConversation, adfEmail })
      // axios.post('http://localhost:5050/send-lead/appointment-lead-chat', { appointment: appointmentDate, dealerId: dealerId, leadInfo, currentConversation, adfEmail })
      .then(data => {
        console.log(data)
        setAllMessages(prev => {
          const copyConvo = [...prev]
          const foundObjIdx = copyConvo.findIndex(obj => obj.user_number === data.data.goodPhone)
          const objCopy = { ...copyConvo[foundObjIdx] }
          objCopy.completed = true
          copyConvo[foundObjIdx] = objCopy
          return copyConvo
        })
        setSelectedChat(null)
        setLeadInfo(null)
        setCurrentConversation([])
        setAppointment('')
        handleClose()
      })
  }
  const sendHotTransfer = () => {
    axios.post('https://twilio-server-snap.herokuapp.com/hot-transfer/create-link', { BBID: dealerId, userPhone: selectedChatNumber })
      .then(data => {
        console.log(data)
      })
      .catch(err => {
        console.log(err)
      })
    // console.log(selectedChatNumber, dealerId)
  }
  const handleTabChange = (e, newValue) => {
    setTableValue(newValue)
    setSelectedChat(null)
    setLeadInfo(null)
    setCurrentConversation([])
  }
  return (
    <>
      {
        dealerId ?
          <div className={styles.container}>

            <Modal
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <div>
                    <p>Enter the appointment date and time.</p>
                    <TextField
                      multiline
                      style={{ marginBottom: '5px', width: '100%' }}
                      variant="outlined"
                      value={appointmentDate}
                      onChange={(e) => setAppointment(e.target.value)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ backgroundColor: 'green' }}
                        onClick={submitAppointmentLead}
                      >
                        Create
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: '#fff' }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>

                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="wrapped label tabs example">
              <Tab value={0} label="New" />
              <Tab value={1} label="Completed" />
            </Tabs>
            {tabValue === 0 &&
              <div className={styles.bottomHalf}>
                <div className={styles.leftColumn}>
                  <Messages allMessages={allMessages} to={selectedChatNumber} handleChatSelect={handleConvoChange} />
                </div>
                <div className={styles.middleColumn}>
                  <div className={styles.top}>
                    <ChatWindow twilioNumber={twilioNumber} currentConversation={currentConversation} />
                  </div>
                  <div className={styles.bottom}>
                    <SendBox twilioNumber={twilioNumber} to={selectedChatNumber} />
                  </div>
                </div>
                <div className={styles.rightColumn}>
                  <div className={styles.top}>
                    <ClientDetails leadInfo={leadInfo} />
                  </div>
                  <div className={styles.bottom}>
                    <ActionContainer hotTransfer={sendHotTransfer} allMessages={allMessages} currentChat={selectedChatNumber} openTextBox={handleOpen} submitLead={submitLead} leadInfo={leadInfo} />
                  </div>
                </div>
              </div>
            }
            {tabValue === 1 &&
              <div className={styles.bottomHalf}>
                <div className={styles.leftColumn}>
                  <Messages allMessages={completedMessages} to={selectedChatNumber} handleChatSelect={handleConvoChange} />
                </div>
                <div className={styles.middleColumn}>
                  <div className={styles.top}>
                    <ChatWindow twilioNumber={twilioNumber} currentConversation={currentConversation} />
                  </div>
                  <div className={styles.bottom}>
                    <SendBox twilioNumber={twilioNumber} to={selectedChatNumber} />
                  </div>
                </div>
                <div className={styles.rightColumn}>
                  <div className={styles.top}>
                    <ClientDetails leadInfo={leadInfo} />
                  </div>
                  <div className={styles.bottom}>
                    <ActionContainer hotTransfer={sendHotTransfer} allMessages={allMessages} currentChat={selectedChatNumber} openTextBox={handleOpen} submitLead={submitLead} leadInfo={leadInfo} />
                  </div>
                </div>
              </div>
            }
          </div>
          :
          'Choose a dealer to continue'
      }
    </>
  )
}