import React, { useState } from "react";

import {
  Text,
  Box,
  Flex,
  Modal
} from "@bigcommerce/big-design";
import { Button } from "@material-ui/core";

export const PopOverInvite = props => {
  const [isOpen, setIsOpen] = useState(false);
  //const [buttonRef, setButtonRef] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);
  const postUrl = (url, data) => {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then(response => response.json());
  };

  const handleclick = async event => {
    event.preventDefault();
    let invite = await postUrl(
      `${process.env.REACT_APP_BASE_URL}/api/dash/invite`,
      { bot: props.bot }
    );
    await setInviteLink(invite);
    await setIsOpen(true);
  };
  return (
    <>
      <Button
        //ref={setButtonRef}
        onClick={handleclick}
        variant="contained"
        style={{ backgroundColor: "#26B247", color: "#ffffff" }}
      >
        Invite Team Member
      </Button>

      <Modal
        actions={[
          { text: "Close", variant: "subtle", onClick: () => setIsOpen(false) }
        ]}
        header="Send an invitation link"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnEscKey={true}
        closeOnClickOutside={false}
      >
        <Text>Copy and send this link to a teammate</Text>
        <Text>
          <Flex justifyContent="space-around">
            <Box
              backgroundColor="secondary10"
              padding="xxLarge"
              shadow="floating"
            >
              {inviteLink}
            </Box>
          </Flex>
        </Text>
      </Modal>
    </>
  );
};
