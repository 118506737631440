import React from 'react'
import styles from './DataBox.module.scss'

export const DataBox = (props) => {

  return(
    <div>
      <p>{props.title}</p>
      <h1>{props.data}</h1>
    </div>
  )
}