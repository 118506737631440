import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetDealer: "[Dealer] Action"
};

const initialState = {
  dealer: undefined
};

export const reducer = persistReducer(
  { storage, key: "dealerstorage" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetDealer:
        //let dealerId = action.payload.dealer;

        return { ...state, dealer: action.payload.dealer };

      default:
        return state;
    }
  }
);

export const actions = {
  setDealers: dealer => ({ type: actionTypes.SetDealer, payload: { dealer } })
};
