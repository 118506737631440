/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './Intent.module.scss'
import { Paper, Button, MenuList, MenuItem } from "@material-ui/core";
import { Grid, GridItem, Flex, Box , ProgressBar } from '@bigcommerce/big-design';
import { IntentAddForm } from './IntentAdd/IntentAddForm';
import { ExampleAdd } from './ExampleAdd/ExampleAdd';
import { MassUpdate } from './MassUpdate/MassUpdate';
import { LongSpinner } from '../../Spinner'
export const Intent = (props) => {

    const [intents, setIntents] = useState([])
    const [selectedIntent, setSelectedIntents] = useState([])
    const [intentName, setIntentName] = useState()
    const [loading, setLoading] = useState(false)
    const [exporting, setExporting] = useState(false)
    const [submitText, setText] = useState('')
    const [intentOrExampleLoad, setLoad] = useState(false)
    const [error, setErr] = useState(false)

    useEffect(() => {
        (async () => {
         let intentsFetch  = await fetch(`${process.env.REACT_APP_BASE_URL}api/dash/intents`)
         let intentsJsn = await intentsFetch.json()
         console.log('i am the intens', intentsJsn)
         setLoading(false)
         setIntents(intentsJsn.intents)
        })()
      
    }, [])

    const onItentSelect = (idx) => {

        setSelectedIntents(intents[idx].examples)
        setIntentName(intents[idx].intent)
    }


    const handleSubmit = (inte, arryOfExamples, des) => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}api/dash/create-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ intent: inte, examples: arryOfExamples, des })
        }).then(() => {
            fetch(`${process.env.REACT_APP_BASE_URL}api/dash/intents`)
                .then(res => res.json())
                .then(jsnRes => {
                    console.log(jsnRes.intents)
                    setLoad(false)
                    setIntents(jsnRes.intents)
                })
        })
            .catch(err => {
                setErr(err)
                console.error(err)
            })

    }
    const handleUpdate = (intent, arryOfExamples) => {
        setLoad(true)
        fetch(`${process.env.REACT_APP_BASE_URL}api/dash/update-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ intent, examples: arryOfExamples })
        }).then(() => {
            fetch(`${process.env.REACT_APP_BASE_URL}api/dash/intents`)
                .then(res => res.json())
                .then(jsnRes => {
                    console.log(jsnRes.intents)
                    setLoad(false)
                    setIntents(jsnRes.intents)
                    setSelectedIntents([])
                })
        })
            .catch(err => {
                setErr(err)
                console.error(err)
            })

    }
    const handleExportMessages = async (event) => {
        setExporting(true)
        let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/dash/export/messages`)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "messages.csv";
            document.body.appendChild(a);  
            a.click();    
            a.remove();
            setExporting(false)    
        });
        return data
    }
    console.log(intents)
    if(!intents){
        return <LongSpinner spinner={true}/> 
    }

    return (
        <Paper style={{ margin: 'auto' }}>
            <Grid gridColumns="repeat(2, 1fr)" style={{ padding: '2rem' }}>
                <GridItem>
                    <span>
                        Name your intent to match a customer's question or goal
                    </span>
                    <Grid>
                        <GridItem>
                        </GridItem>
                        <GridItem>
                            <div className={styles.multiButton}>
                                <IntentAddForm addIntent={handleSubmit} title='add new intent'  />
                                <MassUpdate savedIntents={intents} title='Mass update'/>
                                <href  style={{paddingLeft : '.75rem'}} onClick={handleExportMessages} />
                                
                                <Button variant="contained" color="primary" type="submit">
                                {exporting === true ? <div> 
                                    <Flex justifyContent="center">
                                    <Grid gridColumns="repeat(2, 1fr)"> 
                                    <GridItem>
                                    Exporting...
                                    </GridItem>
                                    <GridItem>
                                        <ProgressBar /> 
                                    </GridItem>
                                    </Grid>
                                    </Flex>
                                    </div>: `${'Export All User Chats'}`}
                            </Button >
                           
                            </div>
                        </GridItem>
                    </Grid>
                    <MenuList >
                        {
                            intentOrExampleLoad ?
                                <h1>Refreshing... </h1>
                                :
                                intents.map((obj, idx) => {
                                    return (

                                        <MenuItem button key={obj.intent} onClick={() => onItentSelect(idx)} style={{ borderColor: "#989898", boxShadow: "inset 0 1px 1px #e5e5e5", maxWidth: '50%' }}
                                        >
                                            {obj.intent}
                                        </MenuItem>
                                    )
                                })
                        }
                    </MenuList>
                </GridItem>
                <GridItem>
                    <div className={styles.allExamplesContainer}>
                        <div className={styles.addExample}>
                            <span>
                                Add unique examples of what the user might say.
                            </span>
                            <Grid>
                                <GridItem>
                                </GridItem>
                                <GridItem>

                                    <ExampleAdd intentName={intentName} updateIntent={handleUpdate} text={submitText} textChange={setText} title='add example' />
                                </GridItem>
                                <GridItem>
                                    <span style={{ fontWeight: 'bold' }}>
                                        (Pro tip: Add at least 5 unique examples to help Watson understand)
                                         </span>
                                </GridItem>
                            </Grid>
                        </div>
                        <div className={styles.allExamples}>
                            <Box>
                                {
                                    selectedIntent.map((ex, idx) => {
                                        return (
                                            <div key={idx}>
                                                <p>{ex.text}</p>
                                            </div>
                                        )
                                    })

                                }
                            </Box>
                        </div>
                    </div>
                </GridItem>
            </Grid>
        </Paper>
    )
}