import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { useSelector } from "react-redux";
import Dashboard from "./bigcommerce/Dash/Dashboard";
import { CustomerProfile } from "./bigcommerce/Customer/CustomerProfile/CustomerProfile";
import { Admin } from "./bigcommerce/Admin/Admin";
import { NonAdmin } from "./bigcommerce/Admin/NonAdmin";
import { UsersContainer } from "./bigcommerce/FBUsers/UsersContainer";
// import { Chat } from "./bigcommerce/Chat_refactor/Chat";
import { Reports } from "./bigcommerce/Reports/Reports";
import { Intent } from './bigcommerce/Intent/Intent'
import { Dialog } from './bigcommerce/Dialog/Dialog'
import { Onboarding } from './bigcommerce/Onboarding/Onboarding'
import { Demo } from './bigcommerce/Demo/Demo'
import { SearchDemo } from './bigcommerce/Demo/Search'
import { DealerLeads } from './bigcommerce/Demo/DealerLeads'
import { MassLead } from './bigcommerce/one-time/massLeadSend'
import { Container } from './bigcommerce/SnapMessaging/Container'
import { Total } from './bigcommerce/Total/Total'
import { DealerDetails } from "./bigcommerce/DealerDetails/DealerDetails";
import { MassTradeQuote } from "./bigcommerce/MassTradeQuote/MassTradeQuote";
import { Container as snapAnalytics} from './bigcommerce/SnapWebsiteAnalytics/Container'
import { FBLogin } from "./bigcommerce/FBLogin/FBLogin";
const InventoryRouter = lazy(() =>
  import("./bigcommerce/BCRoutes/InventoryRouter")
);
const LeadsRouter = lazy(() => import("./bigcommerce/BCRoutes/LeadsRouter"));

export default function HomePage(props) {
  const cUser = useSelector(state => state.auth.user);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /quick-start. */
          <Redirect exact from="/" to="/quick-start" />
        }
        <Route path="/quick-start" component={Dashboard} />
        {cUser.email === "test@test.com" ? (
          <Route path="/admin" component={Admin} />
        ) : (
          <Route path="/admin" component={NonAdmin} />
        )}
        {/* <Route path="/tickets" component={ChatLanding} /> */}
        <Route path="/inventory" component={InventoryRouter} />
        <Route path="/leads" component={LeadsRouter} />
        <Route path="/reports" component={Reports} />
        <Route path="/chats" component={Container} />
        <Route path="/intents" component={Intent} />
        <Route path="/dialogs" component={Dialog} />
        <Route path="/onboarding" component={Onboarding} />
        <Route exact path="/total" component={Total} />
        <Route exact path="/total/details/:dealer" component={DealerDetails} />
        <Route exact path="/snap-analytics" component={snapAnalytics} />
        <Route exact path="/mass-quote" component={MassTradeQuote} />
        <Route exact path="/demo" component={Demo} />
        <Route exact path="/demo/search" component={SearchDemo} />
        <Route exact path="/demo/leads" component={DealerLeads} />
        <Route exact path="/test/chat" component={Container} />
        <Route path="/mass-lead-send" component={MassLead} />
        <Route exact path="/customers/profile/:email" component={CustomerProfile} />
        <Route path="/fb-users" component={UsersContainer} />
        <Route path="/fb-login" component={FBLogin} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
