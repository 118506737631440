import React, { useEffect, useState } from 'react'
import { TextField } from "@material-ui/core";

import styles from './SetPassword.module.scss'
import { Spinner } from '../Spinner/Spinner';
import { requestPassword } from "../../../../crud/auth.crud";

export const SetPassword = props => {

	//form states
	const [password, setPassword] = useState('')
	const [confirmPass, setConfirm] = useState('')
	const [correctPass, setCorrect] = useState(false)
	const [passMatch, setMatch] = useState(false)
	const [sixChar, setSix] = useState(false)
	const [upper, setUpper] = useState(false)
	const [lower, setLower] = useState(false)
	const [num, setNum] = useState(false)

	//loading state
	const [isLoading, setLoading] = useState(true)
	const [isUpdating, setUpdating] = useState(false)

	//user state
	const [foundUser, setUser] = useState('')

	//success update state
	const [updated, setUpdated] = useState(false)

	//error state
	const [isError, setError] = useState(false)

	const handlePassControl = (e, num) => {
		const userInput = e.target.value
		if (num === '1') {
			userInput.length >= 6 ? setSix(true) : setSix(false)
			userInput.match(/[a-z]/) ? setLower(true) : setLower(false)
			userInput.match(/[A-Z]/) ? setUpper(true) : setUpper(false)
			userInput.match(/[0-9]/) ? setNum(true) : setNum(false)
			sixChar && upper && lower && num ? setCorrect(true) : setCorrect(false)
			setPassword(userInput)
		}
		else if (num === '2') {
			setConfirm(userInput)
		}
	}

	useEffect(() => {
		fetch(`${process.env.REACT_APP_BASE_URL}/auth/reset-user`, {
			method: 'POST',
			headers: {
				'content-type': "application/json"
			},
			body: JSON.stringify({ token: props.match.params.id })
		})
			.then(results => results.json())
			.then(jsonResults => {
				if (jsonResults.error) {
					setLoading(false)
					setError(jsonResults.msg)
				}

				setUser(jsonResults.doc)
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				setError(err)
			})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		confirmPass === password ? setMatch(true) : setMatch(false)
	}, [password, confirmPass])

	const handleSubmit = () => {
		if (passMatch && correctPass) {
			setUpdating(true)
			requestPassword(foundUser[0].email, password)
				.then(data => {
					setUpdating(false)
					setUpdated(true)
					props.history.push('/')
				})
		}


	}

	//this can be its own component
	let successLoad = (
		<>
			<div className={styles.info}>
				<h1>Set-up Password</h1>
				<p>Password must conatain:</p>
				<ul>
					<li style={!sixChar ? {} : { color: 'green' }}><i className="flaticon2-checkmark"></i>At least 6 characters</li>
					<li style={!upper ? {} : { color: 'green' }}><i className="flaticon2-checkmark"></i>At least 1 uppercase letter</li>
					<li style={!lower ? {} : { color: 'green' }}><i className="flaticon2-checkmark"></i>At least 1 lowercase letter</li>
					<li style={!num ? {} : { color: 'green' }}><i className="flaticon2-checkmark"></i>At least 1 number</li>
				</ul>
			</div>
			<div className={styles.inputs}>
				<TextField
					label="Password"
					onChange={(e) => handlePassControl(e, '1')}
					value={password}
					type="password"
				/>
				<TextField
					label="Confirm Password"
					onChange={(e) => handlePassControl(e, '2')}
					value={confirmPass}
					type="password"
					error={!passMatch}
					helperText={!passMatch ? 'Does not match' : ''}
				/>
				<button onClick={handleSubmit} disabled={!passMatch || !correctPass || isUpdating || updated}>{updated ? 'Password Updated' : "Update"}</button>

				{isUpdating ? <Spinner /> : null}
			</div>
		</>)

	let failLoad = (
		<>
			<h2 style={{ textAlign: "center", padding: '20px' }}>{isError}</h2>
		</>
	)

	if (isLoading) return <Spinner />


	return (
		<div className={styles.page}>
			<div className={styles.container}>
				{isError ? failLoad : successLoad}
			</div>
		</div>
	)
}