import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Collapse from "@material-ui/core/Collapse";

export const DataSide = ({ subs }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Paper style={{ width: "60%", padding: "40px" }}>
      <List>
        {subs &&
          subs.map(obj => (
            <>
              <ListItem key={obj.id} button onClick={handleClick}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      width: "90px",
                      height: "90px",
                      marginRight: "20px"
                    }}
                    src={obj.plans.image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={obj.plans.name}
                  secondary={`${obj.plans.amount} / ${obj.plans.billing_frequency}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem style={{ marginLeft: "25px" }} key={obj.id + 1}>
                    <ListItemText
                      primary={`Subscription period: ${obj.current_period_start} through ${obj.current_period_end}`}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          ))}
      </List>
    </Paper>
  );
};
