import React from 'react'
import { Button } from "@material-ui/core";

export const SendLead = (props) => {

  return (
    <Button 
      onClick={props.submitLead} 
      disabled={!props.leadInfo} 
      style={{ minWidth: '160px', maxWidth: '160px' }}
      variant="contained" 
      size="large"
    >
      SUBMIT LEAD
    </Button>
  )
}
