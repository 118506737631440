import React, { useEffect, useState } from 'react'
import { DataBox } from '../components/DataBox'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios'
import styles from './Stats.module.scss'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "./Stats.css"
export const Stats = (props) => {
  const starting = new Date().setDate(new Date().getDate() - 30)

  const [startDate, setStartDate] = useState(new Date(starting));
  const [endDate, setEndDate] = useState(new Date());
  const [statData, setStatData] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectedFormData, setFormData] = useState('')
  useEffect(() => {
    setFormData("")
    setStartDate(new Date(starting))
    setEndDate(new Date())
    axios.post(`https://needle-api.herokuapp.com/analytics/grab-stats`, {
    // axios.post(`http://localhost:5000/analytics/grab-stats`, {
      dealerId: props.dealerId,
      startDate,
      endDate
    })
      .then(({ data }) => {
        setStatData(data)
        setLoading(false)
      })
  }, [props.dealerId])

  const handleUpdateStats = () => {
    setLoading(true)
    axios.post(`https://needle-api.herokuapp.com/analytics/grab-stats`, {
      dealerId: props.dealerId,
      startDate,
      endDate
    })
      .then(({ data }) => {

        setStatData(data)
        setLoading(false)
      })
  }
  const handleStatSelect = (e) => {
    setFormData(e.target.value)
  }


  if (loading) {
    return <h1>Loading...</h1>
  }
  return (
    <div style={{height: '100%'}}>
      <select value={selectedFormData} onChange={handleStatSelect}>
        <option disabled hidden value="">FORM SELECT</option>
        {
          statData.dataSets.map((name, idx) => {

            return <option key={idx} value={name}>{name}</option>
          })
        }

      </select>
      {
        selectedFormData ?
          <>
            <div className={styles.dataContainer}>
              <DataBox title='Total Visitors' data={((statData.rawData[selectedFormData].returningVisitors || 0) + (statData.rawData[selectedFormData].uniqueVisitors || 0)) || 0} />
              <DataBox title='Unique Visitors' data={statData.rawData[selectedFormData].uniqueVisitors || 0} />
              <DataBox title='Returning Visitors' data={statData.rawData[selectedFormData].returningVisitors || 0} />
              <DataBox title='Mobile Views' data={statData.rawData[selectedFormData].mobileView || 0} />
              <DataBox title='Desktop Views' data={statData.rawData[selectedFormData].desktopView || 0} />
              <DataBox title='Step 1' data={statData.rawData[selectedFormData].step1 || 0} />
              <DataBox title='Step 2' data={statData.rawData[selectedFormData].step2 || 0} />
              <DataBox title='Step 3' data={statData.rawData[selectedFormData].step3 || 0} />
              <DataBox title='Step 4' data={statData.rawData[selectedFormData].step4 || 0} />
            </div>
            <div className={styles.dateRange}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
              <button onClick={handleUpdateStats}>UPDATE</button>
            </div>
            <div className={styles.chartContainer}>
              <LineChart
                width={1000}
                height={600}
                data={statData.chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="mobile" stroke="#2e9ff1" strokeWidth={2}/>
                <Line type="monotone" dataKey="desktop" stroke="#e12ef1" strokeWidth={2}/>
                <Line type="monotone" dataKey="uniqueVisitors" stroke="#f1802e" strokeWidth={2}/>
                <Line type="monotone" dataKey="returningVisitors" stroke="#3df12e" strokeWidth={2}/>
              </LineChart>
            </div>
          </>
          :
          <h1>Choose a form</h1>
      }
    </div>
  )
}

