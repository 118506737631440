import React, { useEffect, useState } from 'react'

export const FBLogin = () => {

  const [fbDone, setfbDone] = useState(false)
  const [fbSession, setSession] = useState(null)
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '426869632049444',
        cookie: true,
        xfbml: true,
        version: 'v9.0'
      });

      window.FB.AppEvents.logPageView();
      setfbDone(true)
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }, [])

  useEffect(() => {
    if (fbDone) {
      console.log('i am coming from the useEffect because i finished loading the sdk')
      window.FB.getLoginStatus(function (response) {
        console.log('checked status from useEffect')
        console.log(response);
        setSession(response)
      });

    }

  }, [fbDone])

  const checkLoginState = () => {
    console.log('i am coming from the fb login button')
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        console.log('i am logged in and ready to go')
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token 
        // and signed request each expire.
        var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;
      } else if (response.status === 'not_authorized') {
        console.log('i am not authorized')
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
      } else {
        console.log(' i am not logged in ')
        // The user isn't logged in to Facebook. You can launch a
        // login dialog with a user gesture, but the user may have
        // to log in to Facebook before authorizing your application.
      }

    })

  }
  const fbLogOut = () => {
    console.log('need to check if we are logged in')
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        console.log('logged in')
        window.FB.logout(function (response) {
          console.log("Logging Out!");
          console.log(response);
          window.location.reload()

        });

      }
    });
  }

  return (
    <div>
      <div id="fb-root"></div>
      <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=426869632049444&autoLogAppEvents=1" nonce="FpW2AgSU"></script>
      <div
        class="fb-login-button"
        data-width=""
        data-size="large"
        data-button-type="continue_with"
        data-layout="default"
        data-auto-logout-link="true"
        data-use-continue-as="true"
        data-scope='pages_manage_metadata'
        onlogin={checkLoginState}
      ></div>
      <button onClick={fbLogOut}>Click to log out</button>
    </div>
  )
}