import React from 'react'
import { IndivConvo } from './IndivConvo'
import styles from './Messages.module.scss'

export const Messages = (props) => {

  return (
    <div className={styles.container}>
      { props.allMessages ?
        props.allMessages.map( message => {
          return <IndivConvo onClick={props.handleChatSelect} to={props.to} key={message.user_number} person={message}/>      
        })

        :

        'Loading...'
      }
    </div>
  )
}