import React, { useEffect, useState } from 'react'
import styles from './Total.module.scss'
import axios from 'axios';
import { Card } from '@material-ui/core';
import { Link } from "react-router-dom";
export const Total = () => {

  const [totalBought, setTotalBought] = useState(null)
  const [dealerNames, setDealerNames] = useState([])
  const [mappedData, setData] = useState(null)
  const [mappedSoldData, setSoldData] = useState(null)
  const [totalMatched, setTotal] = useState(null)
  const [leads, setTotalLeads] = useState(null)
  const [totalLeads, setAllLeads] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.get('https://needle-api.herokuapp.com/snap-autos/getAppraisalData')
    // axios.get('http://localhost:5000/snap-autos/getAppraisalData')
      .then(data => {
        setTotalBought(data.data.boughtCount)
        setDealerNames(data.data.dealerNames)
        setData(data.data.sortedDealers)
        setSoldData(data.data.sortedDealersBought)
        setTotal(data.data.matchCount)
        setTotalLeads(data.data.sortedDealersTotalLeads)
        setAllLeads(data.data.totalLeads)
        setLoading(false)
      })
  }, [])

  return (
    <div className={styles.flexWrap}>
      {
        loading === false ?
          dealerNames.map(dName => {
            return (
              <Card key={dName} className={styles.rootCard}>
                <h3>Dealer Name: {dName}</h3>
                <p>Total Leads Sent: {leads[dName] ? leads[dName] : 'none'}</p>
                <p>Total Appraised: {mappedData[dName] ? mappedData[dName].length : 'none'}</p>
                <p>Total Bought: {mappedSoldData[dName] ? mappedSoldData[dName].length : 'none'}</p>
                <Link className={styles.button} to={{pathname:`/total/details/${dName}`, state:{mappedData, mappedSoldData, leads} }}>
                  Click for more
                </Link>
              </Card>
            )
          })

          :

          <h1>Gathering Data...</h1>

      }
      <Card className={styles.rootCard}>
        <h3>Totals</h3>
        <p>Total Leads: {!totalLeads ? "Loading..." : totalLeads}</p>
        <p>Total Appraised: {!totalMatched ? "Loading..." : totalMatched}</p>
        <p>Total Bought: {!totalBought ? 'Loading...' : totalBought}</p>
      </Card>
    </div>
  )
}