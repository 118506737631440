import React, { useEffect, useState } from "react";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Search } from "../components/search/Search";
import {BasicTableSearch} from './TableSearch'

const useStyles = makeStyles((theme)=>({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        minWidth: '100%',
        height: '100%'
    },
    leadCard: {
        minWidth: '100%',
        height: '50%'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    formControl: {
        margin: 5,
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: 10,
      },
}));

export const SearchDemo = props => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('')
    const [foundUsers, setFoundUsers] = useState({data: false})
    const [loading, setLoading] = useState(false)
    const handleValueChange = (e) => {
      setSearchValue(e.target.value)
    }
    const handleSubmit = async () => {
      if(searchValue.length >= 3){
        setLoading(true)
       let {data} =  await axios.post('http://localhost:5000/vin-solutions/find-contact?text=test',{text:searchValue })
      console.log(data)
       setFoundUsers(data)
      }
    }

    useEffect(()=>{
      setLoading(false)
    }, [foundUsers])

    if(loading){
      return <h1>Loading Contacts</h1>
    }



    return (
        <div style={{height: '100%'}}>
            {/* top level dealer search */}
            <div>
                <div>
                    <Search submitSearch={handleSubmit} handleValueChange={handleValueChange} value={searchValue}/>
                </div>
            </div>
        {
          foundUsers.data ? 
            <div style={{display: 'flex', height: '80%'}}>
                <div style={{width: '100%'}}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Users
                            </Typography>
                            <BasicTableSearch users={foundUsers} />
                        </CardContent>
                    </Card>
                </div>
            </div>

          :
          
            <h1>No results</h1>

        }
        </div>
    )
}