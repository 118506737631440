import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CardCustom } from "./material-ui/Card";
import styles from "./Reports.module.scss";

import { Flex, Grid, GridItem } from "@bigcommerce/big-design";
import { Chart } from "./chart/chart";
import { ExportButton } from "./export/ExportButton";
import { MatchBackButton } from "./matchback/MatchBackButton";

export const Reports = () => {
  const dealerId = useSelector(state => state.dealer.dealer);
  const [dealer30DayData, set30DayData] = useState(null);
  const [dealer15DayData, set15DayData] = useState(null);
  const [dealerMTDData, setMTDData] = useState(null);
  const [dealerSNAPData, setSNAPData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [cfData, setCFdata] = useState(null)
  useEffect(() => {
    if (dealerId) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/analytics/lead-data/${dealerId}/snap`
      )
        .then(res => res.json())
        .then(jsnData => {
          setSNAPData(jsnData);
        })
    }
  }, [dealerId]);
  useEffect(() => {
    if (dealerId) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/analytics/lead-data/${dealerId}/mtd`
      )
        .then(res => res.json())
        .then(jsnData => {
          setMTDData(jsnData);
        })
    }
  }, [dealerId]);
  useEffect(() => {
    if (dealerId) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/analytics/lead-data/${dealerId}/chart-data`
      )
        .then(res => res.json())
        .then(jsnData => {
          setChartData(jsnData);
        })
    }
  }, [dealerId]);
  useEffect(() => {
    if (dealerId) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/analytics/lead-data/${dealerId}/30`
      ).then(res => res.json())
        .then(jsnData => {
          set30DayData(jsnData);
        })
    }
  }, [dealerId]);

  useEffect(() => {
    if (dealerId) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/analytics/lead-data/${dealerId}/15`
      ).then(res => res.json())
        .then(jsnData => {
          set15DayData(jsnData);
        })
    }
  }, [dealerId]);

  useEffect(() => {
    if (dealerId) {
      setCFdata(dealerId)
      // fetch(
      //   `${process.env.REACT_APP_BASE_URL}/api/analytics/chatbot-data/${dealerId}`
      // ).then(res =>
      //   res.json()
      // )
      //   .then(jsnData => {
      //     console.log(jsnData);
      //     setCFdata(jsnData);
      // })
    }
  }, [dealerId]);
  if (!dealerId) {
    return (
      <>
        <Grid>
          <GridItem>
            <Flex justifyContent="center">
              <h1>Please first select a dealer before continuing</h1>
            </Flex>
          </GridItem>
        </Grid>
      </>)
  }

  return (
    <div>
      <div>
        <ExportButton thirtyDays={dealer30DayData} fifteenDays={dealer15DayData} mtd={dealerMTDData} chartData={chartData} dealerId={dealerId}/>
        <MatchBackButton />
      </div>

      <div className={styles.container}>
        {/* {cfData ? */}
          <CardCustom
            title="Custom Date Range"
            custom="true"
            chatbotStatistics={cfData}
            dealer={dealerId}
          />
        {/* : ''} */}
        {dealer30DayData ?
          <CardCustom
            title="Running 30 Days"
            totalLeads={dealer30DayData.leads}
            totalCustomers={dealer30DayData.total_conversations}
          /> : ''}
        {dealer15DayData ?
          <CardCustom
            title="Running 15 Days"
            totalLeads={dealer15DayData.leads}
            totalCustomers={dealer15DayData.total_conversations}
          /> : ''}
        {dealerMTDData ?
          <CardCustom
            title="Month to Date"
            totalLeads={dealerMTDData.leads}
            totalCustomers={dealerMTDData.total_conversations}
            /> : ''}
        {dealerSNAPData ?
          <CardCustom
            title="SnapAuto Leads - MTD"
            snapLeads={dealerSNAPData.snapLeads}
            landingLeads={dealerSNAPData.landingPageLeads}
            miles={dealerSNAPData.milesThresh}
            appointments={dealerSNAPData.appointments}
            wholesaleLeads={dealerSNAPData.wholesaleLeads}
          /> : ''}
      </div>
    </div>
  );
};
