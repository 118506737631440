/* eslint-disable no-restricted-imports */
import React from 'react'
//import styles from './ExportButton.module.scss'
import Button from '@material-ui/core/Button';
import {saveAs} from 'file-saver'
import axios from 'axios'
export const ExportButton = ({thirtyDays, fifteenDays, mtd, dealerId, chartData}) => {

  const handleExport = () =>{
    axios.post(`${process.env.REACT_APP_NEEDLE_API}/create-pdf`, {mtd, thirtyDays, fifteenDays, dealerId, chartData})
    .then(()=> axios.get(`${process.env.REACT_APP_NEEDLE_API}/create-pdf`, {responseType: 'blob'}))
    .then((res) => {
      const pdfBlob = new Blob([res.data], {type: 'application/pdf'})
      saveAs(pdfBlob, 'report.pdf')
    })
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{marginBottom: 10}}
      disabled={false}
      onClick={handleExport}
    >
      Export Report
    </Button>
  )
}