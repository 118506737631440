import React, { useEffect, useState } from "react";

import {
  Form,
  FormGroup,
  Input,
  Text,
  Flex,
  Modal,
  Switch
} from "@bigcommerce/big-design";
import { IconButton, Typography, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export const BotSettings = props => {
  const [isOpen, setIsOpen] = useState(false);
  //const [buttonRef, setButtonRef] = useState(null);
  const [botDealer, setBotDealer] = useState(null);
  const [checked, setChecked] = useState(false);
  const [adfEmail, setAdfEmail] = useState("");

  const [emailError, setEmailError] = useState(null);

  // const putUrl = (url, data) => {
  //   return fetch(url, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(data)
  //   }).then(response => response.json());
  // };

  function getUrl(url) {
    return fetch(url, {
      method: "GET"
    }).then(response => response.json());
  }

  const handleclick = async event => {
    event.preventDefault();
    if (isOpen === false) {
      await setIsOpen(true);
    }
    if (isOpen === true) {
      await setIsOpen(false);
    }
  };

  useEffect(() => {
    (async function load() {
      let dealer = await getUrl(
        `${process.env.REACT_APP_BASE_URL}/api/dash/bot/${props.bot}`
      );
      setBotDealer(dealer);
      (dealer && dealer.adf_enabled === "yes") ? setChecked(true) : setChecked(false);
      validateErrors(checked);
      setAdfEmail(dealer && dealer.adf_email ? dealer.adf_email : '');
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdates = async event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!emailError) {
      setIsOpen(false);
    }

    let body = {
      adf_enabled: checked === true ? "yes" : "no",
      adf_email: adfEmail
    };
    console.log(body);
    //  await putUrl(`${process.env.REACT_APP_BASE_URL}api/cloud/dealers/${botDealer._id}`, body)
  };

  const validateErrors = enabled => {
    if (adfEmail.length) {
      /.+@.+\.[A-Za-z]+$/.test(adfEmail)
        ? setEmailError(null)
        : setEmailError("invalid email");
    }
    if (adfEmail.length === 0 && enabled === true) {
      setEmailError("email required");
    }

    if (adfEmail.length === 0 && enabled === false) {
      setEmailError(null);
    }
    if (adfEmail.length !== 0 && enabled === false) {
      setEmailError("adf required");
    }
  };

  const handleChange = event => {
    const { target } = event;
    setAdfEmail(target.value);
    validateErrors(checked);
  };

  const handleSwitch = event => {
    setChecked(event.currentTarget.checked);
    validateErrors(event.currentTarget.checked);
  };

  return (
    <>
      <IconButton aria-label="settings" onClick={handleclick}>
        <MoreVertIcon />
      </IconButton>
      {botDealer !== null ? (
        <Modal
          actions={[{ text: "Close", variant: "subtle", onClick: handleclick }]}
          header={botDealer.dealer_name}
          isOpen={isOpen}
          onClose={handleclick}
          closeOnEscKey={true}
          closeOnClickOutside={false}
        >
          <Flex justifyContent="space-around">
            <Typography variant="body2" color="textSecondary" component="p">
              <Text>{botDealer.address.street}</Text>
              <Text>{botDealer.address.city}</Text>
              <Text>{botDealer.address.state}</Text>
              <Text>{botDealer.address.zip}</Text>
              <Text>{botDealer.phone}</Text>
              <Text>{botDealer.site_base_url}</Text>
            </Typography>
            <Flex justifyContent="space-between">
              <Form autoComplete="off" onSubmit={event => handleUpdates(event)}>
                <FormGroup>
                  <Input
                    id="outlined-name"
                    label="ADF Email"
                    placeholder={adfEmail}
                    value={adfEmail}
                    onChange={handleChange}
                    onBlur={handleChange}
                    variant="outlined"
                    error={emailError}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  Enable ADF
                  <Switch
                    checked={checked}
                    onChange={event => handleSwitch(event)}
                  />
                </FormGroup>
                <FormGroup>
                  {emailError ? (
                    <Button
                      disabled
                      variant="contained"
                      type="submit"
                      style={{ backgroundColor: "#f89ea7", color: "#ffffff" }}
                    >
                      submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ backgroundColor: "#26B247", color: "#ffffff" }}
                    >
                      Submit
                    </Button>
                  )}
                </FormGroup>
              </Form>
            </Flex>
          </Flex>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
