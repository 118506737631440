import React, { useEffect, useState } from 'react'
import styles from './UsersContainer.module.scss'
import axios from 'axios'
// import { CreateLeads } from './CreateLeads/CreateLeads'
import { UsersSearch } from './UsersSearch/UsersSearch'
import { UsersTable } from './UsersTable/UsersTable'
import { useSelector } from "react-redux";

export const UsersContainer = () => {
    const dealerId = useSelector(state => state.dealer.dealer);
    const [dbUsers, setDBUsers] = useState([])

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/fb-users/get-all/${dealerId}`)
        .then(data => setDBUsers(data.data))
    }, [dealerId])

    // const reloadLeads = () => {
    //     axios.get(`${process.env.REACT_APP_BASE_URL}/api/leads/all-leads/${dealerId}`)
    //     .then(data => setDBLeads(data.data))
    // }

    return (
        <div>
            <div className={styles.topBar}>
                {/* <CreateLeads reload={reloadLeads}/> */}
                <UsersSearch />
            </div>
            <div>
                <UsersTable found={dbUsers} />
            </div>
        </div>
    )
}