import React, { useEffect } from 'react'
import { DataBox } from './components/DataBox'
// import styles from './Container.module.scss'
import axios from 'axios'
import { useSelector } from "react-redux";
import { Stats } from './Stats/Stats';
export const Container = () => {
  const dealerId = useSelector(state => state.dealer.dealer);
  //   useEffect(() => {
  //     // axios.get(`${process.env.REACT_APP_NEEDLE_API}/api/leads/all-leads/${dealerId}`)
  //     //     .then(data => setDBLeads(data.data))
  // }, [dealerId])
  return (
    <div>
      {dealerId ?
        <Stats dealerId={dealerId} />
        :
        <h1>Choose a dealer to see stats</h1>
      }
    </div>
  )
}