import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetActivechat: "[Activechat] Action"
};

const initialState = {
  activechat: undefined
};

export const reducer = persistReducer(
  { storage, key: "activechat" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetActivity:
        return { ...state, activechat: action.payload.activechat };

      default:
        return state;
    }
  }
);

export const actions = {
  setActivechat: activechat => ({
    type: actionTypes.SetActivechat,
    payload: { activechat }
  })
};
