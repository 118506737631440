import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetLoader: "[Loader] Action"
};

const initialState = {
  loader: false
};

export const reducer = persistReducer(
  { storage, key: "loader" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetDealer:

        return { ...state, loader: action.payload.loader };

      default:
        return state;
    }
  }
);

export const actions = {
  setLoader: loader => ({ type: actionTypes.SetLoader, payload: { loader } })
};
