import React, {useEffect, useState} from "react";
import style from './LeadViewWPics.module.scss'
import axios from 'axios';

export const LeadViewWPics = () => {
  const [leadInfo, setLeadInfo] = useState(null)

  useEffect(()=>{
    axios.get('http://localhost:5000/snap-autos/grab-lead/valueyourtrade/+1 201-937-5215')
    .then(data => {
      console.log(data.data.data)
      setLeadInfo(data.data.data)
    })
  }, [])

  return (
    <div className={style.container}>
      <div className={style.header}>
        <img width='112px' alt="snap-logo" src="https://snapautos.com/wp-content/uploads/2021/08/snapautosbuyer.png"/>
      </div>
      <div className={style.imageBackground}>

      </div>
      <h1>Lead Information</h1>
      {
        leadInfo ?

          <div>
            <p>Name: {leadInfo.full_name || `${leadInfo.first_name} ${leadInfo.last_name}`}</p>
            <p>Phone Number: {leadInfo.phone}</p>
            <p>Email: {leadInfo.email}</p>
            <p>Dealership: {leadInfo.snap_auto_name}</p>
            <p>Lead Source: {leadInfo.leadType}</p>
            <div>
              {leadInfo.Trade_In_Vehicles.map((car, idx) => (
                <div>
                  <p>Vehicle Submitted #{idx + 1}</p>
                  <p>========================</p>
                  <p>Year: {car.year}</p>
                  <p>Make: {car.make}</p>
                  <p>Model: {car.model}</p>
                  <p>Vin: {car.vin}</p>
                  <p>Offer Low: {car.tradeNumbers.low}</p>
                  <p>Offer Mid: {car.tradeNumbers.target}</p>
                  <p>Offer High: {car.tradeNumbers.high}</p>
                  <p>Prospects Intention: {car.intention}</p>
                  {(car.photo && car.photo.length > 0) && 
                    car.photo.map(photo => {
                      return <img width="300px" src={photo.Location} alt='car' />
                    })
                  }
                </div>
              )
              )
              }
            </div>
          </div>
          :

          <h1>Loading Info...</h1>
      }
    </div>
  )
}