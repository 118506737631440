import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  exampleForm:{
    marginTop: 10
  },
  buttonContainer:{
    marginTop: 10
  }
}));

export const IntentAddForm = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [intent, setIntent] = useState();
  const [description, setDes] = useState();
  const [example1, setExample1] = useState();
  const [example2, setExample2] = useState();
  const [example3, setExample3] = useState();
  const [example4, setExample4] = useState();
  const [example5, setExample5] = useState();

  const makeIntent = ()=> {
    setOpen(false)
    setIntent()
    setDes()
    setExample1()
    setExample2()
    setExample3()
    setExample4()
    setExample5()
    props.addIntent(intent, [example1, example2, example3, example4, example5], description)
  }




  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{marginRight: '10px'}}>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {props.title}
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Please Provide a new intent name</h2>
            <Input
              fullWidth
              value={intent}
              name="intent"
              onChange={(e) => setIntent(e.target.value)}
              placeholder="Name of new Intent"

            />
            <h2>Please Provide a description</h2>
            <Input
              fullWidth
              value={description}
              name="description"
              onChange={(e) => setDes(e.target.value)}
              placeholder="Brief description..."

            />
            <div className={classes.exampleForm}>
              <h2>Please give this new intent some examples</h2>
              <Input
                fullWidth
                value={example1}
                name="example"
                onChange={(e) => setExample1(e.target.value)}
                placeholder="example"

              />
              <Input
                fullWidth
                value={example2}
                name="example"
                onChange={(e) => setExample2(e.target.value)}
                placeholder="example"

              />
              <Input
                fullWidth
                value={example3}
                name="example"
                onChange={(e) => setExample3(e.target.value)}
                placeholder="example"

              />
              <Input
                fullWidth
                value={example4}
                name="example"
                onChange={(e) => setExample4(e.target.value)}
                placeholder="example"

              />
              <Input
                fullWidth
                value={example5}
                name="example"
                onChange={(e) => setExample5(e.target.value)}
                placeholder="example"
              />

            </div>
            <div className={classes.buttonContainer}>
              <Button onClick={makeIntent} variant="contained" color="primary">Create</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
