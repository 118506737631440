/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import UserProfile from "../../../../app/partials/layout/UserProfile";
import { useDispatch } from "react-redux";
import * as dealer from "../../../ducks/dealers";
import { Form, FormGroup, Flex, Select } from "@bigcommerce/big-design";
import { getDealers } from '../../../../app/crud/cc.api'
export default function Topbar(props) {
  const dispatch = useDispatch();
  const [dealerOptions, setDealerOptions] = useState([]);
  const [value, setValue] = useState("Select Dealer");


  useEffect(() => {
    (async function() {
      try{
        const dealerOpts = []
        let {data} = await getDealers()
         data.forEach(x => {
          let val = {
            value:  x._id ? x.buyers_bridge_id ? x.buyers_bridge_id : x._id  : x.PSID,
            content: x.name ? x.name : x.dealer_name,
          };
          dealerOpts.push(val)
        });
        setDealerOptions(dealerOpts.sort((a, b) => (a.content > b.content) ? 1 : -1));     //crawford - added to sort dropdown -07/07/21     
      }catch(e) {
        console.log(e)
      }
    })();
  }, []);
  const handleDealerClick = async v => {
    await setValue(v);
    await dispatch(dealer.actions.setDealers(v));
  };
  useEffect(() => {
    dispatch(dealer.actions.setDealers());
  }, [dispatch]);

  return (
    <Flex justifyContent="flex-end" style={{ color: "#fff" }}>
      <div className="kt-header__topbar">
        <div className="kt-header__brand-nav">
          {dealerOptions.length ? (
            <>
              <Form>
                <FormGroup>
                  <Select
                    action={{
                      onActionClick: () => null
                    }}
                    style={{ color: "#fff" }}
                    filterable={false}
                    maxHeight={300}
                    onOptionChange={handleDealerClick}
                    options={dealerOptions}
                    placeholder={"Select Dealer"}
                    placement={"bottom-start"}
                    required
                    value={value}
                  />
                </FormGroup>
              </Form>
            </>
          ) : (
            ""
          )}
        </div>
        <div style={{ paddingLeft: "1rem" }}>
          <UserProfile showAvatar={false} showHi={true} showBadge={true} />
        </div>
      </div>
    </Flex>
  );
}
