export default {
  header: {
    self: {},
    items: [
      {
        title: "Quick Start",
        root: true,
        alignment: "left",
        page: "quick-start",
        icon: "flaticon-star"
      },
      {
        title: "Inventory",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon-list",
        page: "inventory"
      },
      {
        title: "Leads",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon-rotate",
        page: "leads"
      },
      {
        title: "Reports",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon-paper-plane-1",
        page: "reports"
      },
      {
        title: "FB Convo",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon2-avatar",
        page: "fb-users"
      },
      {
        title: "Chat",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon-list",
        page: "chats"
      },
      // {
      //   title: "Intents",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   icon: "flaticon-folder-3",
      //   page: "intents"
      // },
      // {
      //   title: "Dialogs",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   icon: "flaticon-folder-2",
      //   page: "dialogs"
      // },
      {
        title: "Onboarding",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon2-list",
        page: "onboarding/"
      },
      {
        title: "Demo",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon2-list",
        page: "demo/"
      },
      {
        title: "SNAP",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon2-list",
        page: "total/"
      }
    ]
  }
};
