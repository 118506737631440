import React from "react";

export const NonAdmin = props => {
  console.log('im here')
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <h1>You dont have access to this page</h1>
    </div>
  );
};
