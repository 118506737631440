import React, {useState, useEffect} from 'react'
import { makeStyles, MenuItem, FormControl, Select } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export const Mapping = (props) => {
  const classes = useStyles();
  const [mappedIntent, setIntent] = useState('')
  const [previousChoice, setPreviousChoice] =useState(false)
  const handleTheChange = (e) => {
    if(!previousChoice){
      props.onChange(e.target.value, props.pairedIntent, false)
      setIntent(e.target.value)
      
    } else {
      props.onChange(e.target.value, props.pairedIntent, previousChoice)
      setIntent(e.target.value)
    }
  }

  useEffect(()=>{
    setPreviousChoice(mappedIntent)
  }, [mappedIntent])

  return(
    <FormControl className={classes.formControl}>
                <Select
                  value={mappedIntent}
                  onChange={handleTheChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {props.savedIntents.map(el => {
                    return <MenuItem value={el.intent}>{el.intent}</MenuItem>
                    
                  })}
                  
                </Select>
              </FormControl>
  )
}