import React, { useEffect, useState } from "react";
import axios from 'axios'
import { TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { LeadTable } from "./LeadTable";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        minWidth: '100%',
        height: '100%'
    },
    leadCard: {
        minWidth: '100%',
        height: '50%'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    formControl: {
        margin: 5,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 10,
    },
}));

export const DealerLeads = props => {
    const classes = useStyles();
    const [leadSourceIds, setSourceIds] = useState()
    const [selectedId, setSelectedId] = useState('')
    const [foundUsers, setFoundUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {

        async function getIds() {
            let {data} = await axios.get(`https://f9fe0b0059ef.ngrok.io/vin-solutions/get-lead-sources`)
            console.log(data)
            setSourceIds(data)
            setLoading(false)

        }

        getIds()
    }, [])

    useEffect(() => {
        if (selectedId) {
            async function getLeads() {
                try {
                    let data = await axios.get(`https://f9fe0b0059ef.ngrok.io/vin-solutions/get-leads-by-source/${selectedId}`)
                    console.log(data)
                    setFoundUsers(data)
                    setLoading(false)
                    
                } catch (error) {
                    setError(true)
                    setLoading(false)
                    setTimeout(()=>{
                        setError(false)
                    }, 2500)
                }
            }
            getLeads()
        }
    }, [selectedId])

    const handleChange = (e) => {
        setLoading(true)
        setSelectedId(e.target.value)
    }

    if (loading) {
        return <h1>Loading...</h1>
    }
    if (error) {
        return <h1>No data found - Try a different lead source</h1>
    }


    return (
        <div style={{ height: '100%' }}>
            <TextField
                style={{ minWidth: 120 }}
                label="Lead ID"
                select
                variant="outlined"
                value={selectedId}
                onChange={handleChange}
                autoComplete='off'
                inputProps={{
                    name: 'lead_id',
                    id: 'lead_id'
                }}
            >
                {leadSourceIds.map(obj => <MenuItem key={obj.leadSourceId} value={obj.leadSourceId}>{obj.leadSourceName}</MenuItem>)}
            </TextField>
            {
                foundUsers.data ?
                    <div style={{ display: 'flex', height: '80%' }}>
                        <div style={{ width: '100%' }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Contacts
                                    </Typography>
                                    <LeadTable users={foundUsers} />
                                </CardContent>
                            </Card>
                        </div>
                    </div>

                    :

                    <h1>No results</h1>

            }
        </div>
    )
}