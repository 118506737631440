import axios from "axios";
//import { actions } from "../store/ducks/auth.duck";

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const FFL_BASE = `${process.env.REACT_APP_BASE_URL}/api`;

export function searchProducts(search) {
  return axios.get(BASE_URL + `/search/products?${search}`);
}

export function getCustomers() {
  return axios.get(BASE_URL + "/customers");
}


/**** 
 *  NEWER API CALLS
 */

export async function onboardNewClient(data) {
  return await axios.post(BASE_URL + "/onboarding/new", data);
}

export async function getDealers() {
  return await axios.get(BASE_URL + "/cloud/dealers");
}