import React from 'react'
import styles from './IndivConvo.module.scss'

export const IndivConvo = (props) => {

  const handlePhoneSelect = () =>{
    props.onClick(props.person.user_number)
  }

  return(
    <div className={`${styles.container} ${props.to === props.person.user_number && styles.active}`} onClick={handlePhoneSelect}>
      <div className={styles.nameBox}>
        <div className={`${styles.redDot} ${!props.person.newMessage && styles.gone}`}></div>
        <p>{props.person.full_name}</p>
      </div>
      <p className={styles.phoneNumber}>{props.person.user_number}</p>
    </div>
  )
}