import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

export const Search = props => {
  return (
    <TextField
      onChange={props.handleValueChange}
      value={props.value}
      id="input-with-icon-textfield"
      label="Search"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{cursor: 'pointer'}} onClick={props.submitSearch} />
          </InputAdornment>
        )
      }}
    />
  );
};
