import React from "react";
import styles from "./admin.module.scss";
import { CustomTable } from "../components/CustomTable";
export const Admin = props => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <i class="flaticon2-analytics"></i>
        <h1>Admin Dashboard</h1>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.bigButton}>Create new user</div>
        <div className={styles.bigButton}>Set up API</div>
        <div className={styles.bigButton}>Integrations</div>
      </div>
      <div className={styles.tableContainer}>
        <CustomTable
          tableHeaders={["Name", "Email", "Admin", "Joined", "Edit"]}
        />
      </div>
    </div>
  );
};
