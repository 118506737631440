import React, { useState } from "react";
import styles from './UsersTable.module.scss'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  withStyles
} from "@material-ui/core";
import { TablePaginationActions } from "../../helperFunctions/TablePaginationActions";
import { useSelector } from "react-redux";


const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: 0
  }
}))(TableCell);

export const UsersTable = props => {
  const dealerId = useSelector(state => state.dealer.dealer);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    props.found.length > 0
      ? rowsPerPage -
      Math.min(rowsPerPage, props.found.length - page * rowsPerPage)
      : rowsPerPage -
      Math.min(rowsPerPage, props.found.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {dealerId ? (
        <TableContainer component={Paper} style={{ padding: "20px 20px 0 20px" }}>

          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>First</StyledTableCell>
                <StyledTableCell>Last</StyledTableCell>
                <StyledTableCell>Last User Message</StyledTableCell>
                <StyledTableCell>Webview Hit</StyledTableCell>
                <StyledTableCell>Date Added</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {
                (rowsPerPage > 0
                  ? props.found.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : props.found
                )
                .sort()
                  .map((obj, idx) => {
                    return (
                      <TableRow key={idx}>
                        <StyledTableCell>
                          {obj.first_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {obj.last_name}
                        </StyledTableCell>
                        <StyledTableCell className={styles.shortenWord}>
                          {obj.last_user_input}
                        </StyledTableCell>
                        <StyledTableCell>
                          {obj.webview ? "true" : "false"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {obj.time_stamp}
                        </StyledTableCell>
                      </TableRow>
                    )
                  })
              }

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={props.found.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      ) : (
          <h1>Please selected a dealer to see its users</h1>
        )}
    </>
  );
};
