import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



export const LeadTable = (props) => {


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Lead Status</TableCell>
            <TableCell>Lead Status Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
           
          props.users.data ?
          props.users.data.map((row) => (
            <TableRow key={row.ContactId}>
              <TableCell>{row.FirstName}</TableCell>
              <TableCell>{row.LastName}</TableCell>
              <TableCell>{row.Emails[0]? row.Emails[0].EmailAddress : 'None provided'}</TableCell>
              <TableCell>{row.Phones[0] ? row.Phones[0].Number : 'None provided'.Number}</TableCell>
              <TableCell>{new Date(row.createdUtc).toDateString()}</TableCell>
              <TableCell>{row.leadStatusType}</TableCell>
              <TableCell>{row.leadStatus}</TableCell>
            </TableRow>
          ))
            :
            ''
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}