import React from 'react'
import { SendLead } from './SendLead'
import { SetAppointment } from './SetAppointment'
import styles from './ButtonContainer.module.scss'
import { HotTransfer } from './HotTransfer'
export const ActionContainer = (props) => {

  return(
    <div className={styles.container}>
      <SetAppointment onClick={props.openTextBox} leadInfo={props.leadInfo}/>
      <SendLead submitLead={props.submitLead} leadInfo={props.leadInfo}/>
      <HotTransfer onClick={props.hotTransfer}leadInfo={props.leadInfo}/>
    </div>
  )
}