import React, { useEffect, useState } from 'react'
import styles from './DealerDetails.module.scss'
import axios from 'axios';
import { Card } from '@material-ui/core';
export const DealerDetails = (props) => {

  const [dealersAppraised, setDealerApp] = useState([])
  const [dealersBought, setDealerBought] = useState([])
  const [dealersLeads, setDealerLeads] = useState([])
  const [combinedLeads, setDoubleData] = useState([])
  const [totalProfit, setTotalProfit] = useState(0)

  useEffect(() => {
    if (!props.location.state) {
      props.history.goBack()
    }
    setDealerApp(props.location.state.mappedData[props.match.params.dealer])
    setDealerBought(props.location.state.mappedSoldData[props.match.params.dealer] ? props.location.state.mappedSoldData[props.match.params.dealer]: [])
    setDealerLeads(props.location.state.leads[props.match.params.dealer])
  }, [])

  useEffect(() => {
    if (dealersBought.length > 0) {
      axios.post('https://needle-api.herokuapp.com/snap-autos/getInventoryDataFor', { dealer: props.match.params.dealer, bought: dealersBought })
      // axios.post('http://localhost:5000/snap-autos/getInventoryDataFor', { dealer: props.match.params.dealer, bought: dealersBought })
        .then(data => {
          console.log(data)
          setDoubleData(data.data.combinedData)
          setTotalProfit(data.data.totalProfit || "Dealer Data Not Provided")
        })

    }
  }, [dealersBought])
  console.log(dealersBought)
  return (
    <div className={styles.root}>
      <h1 style={{ textAlign: 'center' }}>Dealer Name: {props.match.params.dealer}</h1>
      <div className={styles.OverallInfo}>
        <Card className={styles.Card}>
          <h3>Total Leads: {dealersLeads}</h3>
          <h3>Total Appraised: {dealersAppraised.length}</h3>
          {/* <h3>Total Purchased: {dealersBought.length}</h3> */}
          <h3>Average Profit: {isNaN(Math.round(totalProfit / combinedLeads.length)) ? "Dealer Data Not Provided" : Math.round(totalProfit / combinedLeads.length)}</h3>
          <h3>Total Profit: {totalProfit}</h3>
        </Card>
      </div>
      <h1 style={{ textAlign: 'center' }}>Purchased Details</h1>
      <div className={styles.boughtLeads}>
        {
          combinedLeads.length > 0 ?
            combinedLeads.map(doubleObj => {

              return (
                <Card className={styles.clientCard}>
                  <div>
                    <h3>Customer Info</h3>
                    <p>Name: {doubleObj[0].name}</p>
                    <p>Email: {doubleObj[0].email}</p>
                    <p>Phone: {doubleObj[0].phone}</p>


                  </div>
                  <div>
                    <h3>Vehicle</h3>
                    <p>Year Make Model: {doubleObj[0].year} {doubleObj[0].make} {doubleObj[0].model}</p>
                    <p>VIN: {doubleObj[0].vin}</p>
                    <p>Odometer: {doubleObj[0].mileage}</p>

                  </div>
                  <div>
                    <h3>Quote</h3>
                    <p>Low: {doubleObj[0].tradeNumbers.low}</p>
                    <p>Middle: {doubleObj[0].tradeNumbers.target}</p>
                    <p>High: {doubleObj[0].tradeNumbers.high}</p>

                  </div>
                  <div>
                    <h3>Pricing Info</h3>
                    <p>Pricing: {doubleObj[1].ListedPrice || 'Dealer Data Not Provided'}</p>
                    <p>Cost: {doubleObj[1].TCO}</p>
                    <p>Appraisal: {doubleObj[1].AppraisedValue}</p>

                  </div>
                  <div>
                    <h3>Market Data</h3>
                    <p>MMR: {doubleObj[1].MMR}</p>
                    <p>Profit: {isNaN(parseInt(doubleObj[1].ListedPrice) - doubleObj[1].TCO) ? "Dealer Data Not Provided" : `$${parseInt(doubleObj[1].ListedPrice) - doubleObj[1].TCO}`}</p>
                    
                  </div>
                  <div>
                    <h3>Pricing Info</h3>
                    <p>Auction Fee: $300</p>
                    <p>Transportation Fee: $150</p>
                  

                  </div>
                </Card>
              )
            })
            :

            "None"
        }
      </div>
    </div>
  )
}