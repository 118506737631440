/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import { Card, CardHeader } from "@material-ui/core";
import {
  Paper,
  Grid,
  Typography,
  CssBaseline} from "@material-ui/core";

import { Badge } from "@bigcommerce/big-design";
import { PopOverInvite } from "./PopOverInvite";
import { BotSettings } from "./BotSettings";
const useStyles2 = makeStyles({
  table: {
    minWidth: 500
  },
  tableHead: {
    backgroundColor: "#252525"
  },
  tableHeadCell: {
    color: "#FFFAFA",
    textTransform: "uppercase",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  details: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.25rem"
  },
  tableCell: {
    color: "#252525",
    textTransform: "uppercase",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
});

export default function Dashboard(props) {
  const classes = useStyles2();

  const [bots, setBots] = useState([]);
  function getUrl(url) {
    return fetch(url, {
      method: "GET"
    }).then(response => response.json());
  }

  // const postUrl = (url, data) => {
  //   return fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(data)
  //   }).then(response => response.json());
  // };

  // const initeTeam = bot => {
  //   let invite = postUrl(
  //     `${process.env.REACT_APP_BASE_URL}/api/dash/invite`,
  //     { bot: bot }
  //   );
  //   return invite;
  // };

  useEffect(() => {
    (async function load() {
      let api = await getUrl(
        `${process.env.REACT_APP_BASE_URL}/api/dash/pages`
      );
      console.log(api);
      await setBots(api);
    })();
  }, []);

  return (
    <div className="kt-separator kt-separator--dashed">
      {bots.length !== 0 ? (
        <Paper>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="medium"
              aria-label="custom pagination table"
            >
              <TableBody>
                {bots.map(row => (
                  <TableRow key={row.id}>
                    <TableCell className={classes.tableCell} align="center">
                      <Paper>
                        <Grid item key={row.id} xs={12}>
                          <Card key={row.id} className={classes.root}>
                            <CardHeader
                              action={<BotSettings bot={row.id} />}
                              title={
                                row.status.page_info
                                  ? row.status.page_info.title
                                  : "N/A"
                              }
                              subheader={new Date(
                                row.date_added
                              ).toLocaleDateString("en")}
                            />
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                              style={{ paddingBottom: "2rem" }}
                            >
                              <Grid
                                container
                                spacing={1}
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                style={{ paddingLeft: "2px" }}
                              >
                                <CssBaseline />
                                <Grid item xs={6} sm={2}>
                                  <img
                                    style={{ maxHeight: "100px" }}
                                    className={classes.cardMedia}
                                    component="img"
                                    src={
                                      row.status.page_info
                                        ? row.status.page_info.picture
                                        : ""
                                    }
                                    alt='Img'
                                  />
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    <Badge label="active" variant="success" />
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  {row.admins.map(x => {
                                    let admins = (
                                      <Typography key={x.name}>
                                        {x.name}
                                      </Typography>
                                    );

                                    return admins;
                                  })}
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  <PopOverInvite bot={row.id} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Paper>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        "You Have No Bots!"
      )}
    </div>
  );
}
