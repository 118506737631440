/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import style from "./ProfileSide.module.scss";
import Paper from "@material-ui/core/Paper";

export const ProfileSide = ({ currentPro }) => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = num => {
    setActiveButton(num);
  };

  return (
    <Paper style={{ width: "33%", padding: "40px" }}>
      <div className={style.nameContainer}>
        <span
          style={{
            width: "70px",
            height: "70px",
            marginRight: "10px",
            fontSize: "18px"
          }}
          className={`kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-`}
        >
          {currentPro ? currentPro.first_name[0] : "A"}
        </span>
        <div className={style.iconAndButtons}>
          <p>
            {currentPro.first_name} {currentPro.last_name}
          </p>
          <div className={style.buttonContainer}>
            <div className={style.button}>BUTTON ONE</div>
            <div className={style.button}>BUTTON TWO</div>
          </div>
        </div>
      </div>
      <div className={style.locationContainer}>
        <p>Email: {currentPro.email}</p>
        <p>
          Phone:{" "}
          {currentPro.phone
            ? `(${currentPro.phone.slice(0, 3)}) ${currentPro.phone.slice(
                3,
                6
              )}-${currentPro.phone.slice(-4)}`
            : "No phone number provided"}
        </p>
        <p>City/zip: {currentPro.city ? currentPro.city : currentPro.zip}</p>
      </div>
      <div className={style.accountButtonsContainer}>
        <div
          onClick={() => handleButtonClick(1)}
          className={
            activeButton === 1 ? style.activeButton : style.accountButtons
          }
        >
          Profile Overview
        </div>
        <div
          onClick={() => handleButtonClick(2)}
          className={
            activeButton === 2 ? style.activeButton : style.accountButtons
          }
        >
          Personal Information
        </div>
        <div
          onClick={() => handleButtonClick(3)}
          className={
            activeButton === 3 ? style.activeButton : style.accountButtons
          }
        >
          Account Information
        </div>
        <div
          onClick={() => handleButtonClick(4)}
          className={
            activeButton === 4 ? style.activeButton : style.accountButtons
          }
        >
          etc..
        </div>
      </div>
    </Paper>
  );
};
