import React from "react";
import { Button } from "@material-ui/core/";

export const CustomEditButton = props => {
  return (
    <Button {...props} color="primary">
      {props.title}
    </Button>
  );
};
